import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import image from '../../assets/ErrorPageIMG.png';

function AwSnap() {
  return (
    <>
      <div>
        <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
          <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
            <div className="relative">
              <div className="absolute">
                <div className="">
                  <Typography className="my-2 text-gray-800 font-bold text-2xl">
                    Oops! Error
                  </Typography>
                  <Typography className="my-2 text-gray-800 font-bold text-1xl">
                    Looks like you have found the doorway to the great nothing
                  </Typography>
                  <Typography className="my-2 text-gray-800">
                    Sorry about that! Please visit our hompage to get where you need to go.
                  </Typography>
                  <Button
                    variant="contained"
                    className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
                    href="/"
                  >
                    Back to Home!
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AwSnap;
