import { IPublicClientApplication } from '@azure/msal-browser';
import { IComponent, IEntitiesData, IEntity } from 'app/models';
import { AxiosRequestConfig } from 'axios';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName = 'app-designer';

class EntityService extends RequestHandler {
  endpath = 'entity';

  getComponentEntities(instance: IPublicClientApplication, componentId: string) {
    return this.requestHandler(instance).get<IEntitiesData[]>(
      `/${this.endpath}/getComponentEntities/${componentId}`
    );
  }
  getEntitiesById(instance: IPublicClientApplication, ids: string[], applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IEntity[]>(
      `${this.endpath}/getEntitiesByIds?entityIds=${ids}`,
      config
    );
  }
}

export default new EntityService(apiServiceName);
