import { createSlice } from '@reduxjs/toolkit';
import { IMRA } from 'app/models';
import { fetchMRAs } from '../actions/mra-gallery.action';

type InitialApplicationState = {
  filteredMras: IMRA[];
  previousFilter: { mraType: string; searchText: string; tags: string[] };
  page: number;
  noMoreDocs: boolean;
  searchableTags: [];
  loading: boolean;
};

const initialState: InitialApplicationState = {
  filteredMras: [],
  previousFilter: { mraType: '', searchText: '', tags: [] },
  page: 0,
  noMoreDocs: false,
  searchableTags: [],
  loading: false,
};

const mraGallerySlice = createSlice({
  name: 'mraGallerySlice',
  initialState,
  reducers: {
    resetPage: (state) => {
      state.page = 0;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    resetFilteredMras: (state) => {
      state.filteredMras = [];
    },
    clearStore: (state) => {
      state.filteredMras = [];
      state.page = 0;
      state.previousFilter = { mraType: '', searchText: '', tags: [] };
      state.noMoreDocs = false;
    },
    getAllSearchableTags: (state, action) => {
      state.searchableTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMRAs.fulfilled, (state, action) => {
        state.filteredMras = state.filteredMras.concat(action.payload.data);
        state.previousFilter = action.payload.previousFilter;
        state.noMoreDocs = action.payload.noMoreDocs;
      })
      .addCase(fetchMRAs.rejected, (state) => {
        state.filteredMras = state.filteredMras.concat([]);
      });
  },
});

export const { resetPage, incrementPage, resetFilteredMras, clearStore } = mraGallerySlice.actions;

export default mraGallerySlice.reducer;
