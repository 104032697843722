import { NavigationItem } from 'app/models';
import { PermissionEnum } from 'app/models/common/enums';
import HomeIcon from '@mui/icons-material/Home';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import UserIcon from '@mui/icons-material/Person';
import HandymanIcon from '@mui/icons-material/Handyman';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SettingsIcon from '@mui/icons-material/Settings';
import Layers from '@mui/icons-material/Layers';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ApiIcon from '@mui/icons-material/Api';
import {
  OfflinePinOutlined,
  OnlinePredictionRounded,
  CreateTwoTone,
  ChangeCircleOutlined,
  Outbound,
  Autorenew,
  ChatBubbleOutlineOutlined,
  AutoAwesome,
  Flare,
  Forum,
  GraphicEq,
  MultipleStop,
  ControlCameraOutlined,
  Engineering,
  Description,
  DonutLargeOutlined,
  FilterHdrOutlined,
  Fluorescent,
  LanOutlined,
  PlayForWork,
} from '@mui/icons-material';

export const getDashboardNavigation = (): NavigationItem[] => [
  {
    id: 'home',
    path: '/',
    icon: <HomeIcon />,
    label: 'Home',
  },
  {
    id: 'apps',
    path: '/apps',
    icon: <AppsOutlinedIcon />,
    label: 'Applications',
  },
  {
    id: 'projs',
    path: '/projects',
    icon: <CorporateFareIcon />,
    label: 'Projects',
  },
  {
    id: 'mras',
    path: '/mras',
    icon: <HandymanIcon />,
    label: 'MRAs',
    permissions: [PermissionEnum.VIEW_MRA],
  },
  {
    id: 'users',
    path: '/users',
    icon: <UserIcon />,
    label: 'Users',
    permissions: [PermissionEnum.LIST_USER],
  },
  {
    id: 'user-requests',
    path: '/user-requests',
    icon: <GroupAddIcon />,
    label: 'User-requests',
    permissions: [PermissionEnum.USER_REQUEST],
  },
  {
    id: 'api-factory',
    path: '/apifactory',
    icon: <ApiIcon />,
    label: 'API-Factory',
  },
];
export const getHomeNavigation = (): NavigationItem[] => [
  {
    id: 'home',
    path: '/',
    icon: <HomeIcon />,
    label: 'Rapidx Home',
  },
  {
    id: 'offline',
    path: '/apifactory/ExcelToOAS',
    icon: <OfflinePinOutlined />,
    label: 'Design APIs Offline',
  },
  {
    id: 'online',
    path: '/apifactory/ApiCatalog',
    icon: <OnlinePredictionRounded />,
    label: 'Design APIs Online',
  },
  {
    id: 'build',
    path: '/apifactory/generateApiDoc',
    icon: <CreateTwoTone />,
    label: 'Build APIs ',
  },
  {
    id: 'convert',
    path: '/apifactory/convertToOas',
    icon: <ChangeCircleOutlined />,
    label: 'Convert ',
  },
  {
    id: 'APIeditor',
    path: '/apifactory/apiEditor',
    icon: <Outbound />,
    label: 'APIs Editor',
  },
  {
    id: 'reverseengineering',
    path: '/apifactory/reverseengineering',
    icon: <Autorenew />,
    label: 'Reverse Engineering',
  },
  {
    id: 'chatAPI',
    path: '/apifactory/businessUsecases',
    icon: <ChatBubbleOutlineOutlined />,
    label: 'Chat API',
  },
  {
    id: 'Administration',
    path: '/apifactory/account',
    icon: <Engineering />,
    label: 'Administration',
    permissions: [PermissionEnum.API_FACTORY_ADMIN],
  },
];

export const getOfflineNavigation = (): NavigationItem[] => [
  {
    id: 'apifactory-home',
    path: '/apifactory',
    icon: <ApiIcon />,
    label: 'Apifactory Home',
  },
  {
    id: 'offlineOASform',
    path: '/apifactory/ExcelToOAS',
    icon: <AutoAwesome />,
    label: 'Excel To OAS/RAML',
  },
  // {
  //   id: 'offlineRAMLform',
  //   path: '/apifactory/ExcelToRAML',
  //   icon: <ControlCameraOutlined />,
  //   label: 'Excel To RAML',
  // },
];

export const getBuildAPINavigation = (): NavigationItem[] => [
  {
    id: 'apifactory-home',
    path: '/apifactory',
    icon: <ApiIcon />,
    label: 'Apifactory Home',
  },
  {
    id: 'APICode',
    path: '/apifactory/generateApiCode',
    icon: <Layers />,
    label: 'Generate API-Code',
  },
  {
    id: 'APIDoc',
    path: '/apifactory/generateApiDoc',
    icon: <Flare />,
    label: 'Generate API-Doc',
  },
];

export const getConvertNavigation = (): NavigationItem[] => [
  {
    id: 'apifactory-home',
    path: '/apifactory',
    icon: <ApiIcon />,
    label: 'Apifactory Home',
  },
  {
    id: 'convertToOas',
    path: '/apifactory/convertToOas',
    icon: <MultipleStop />,
    label: 'Convert To OAS',
  },
  // {
  //   id: 'ramlToOas',
  //   path: '/apifactory/ramlToOas',
  //   icon: <Forum />,
  //   label: 'Convert RAML To OAS',
  // },
  {
    id: 'yamlToJson',
    path: '/apifactory/yamlToJson',
    icon: <GraphicEq />,
    label: 'Convert YAML To JSON',
  },
];
export const getAdminNavigation = (): NavigationItem[] => [
  {
    id: 'apifactory-home',
    path: '/apifactory',
    icon: <ApiIcon />,
    label: 'Apifactory Home',
  },
  {
    id: 'accountform',
    path: '/apifactory/account',
    icon: <UserIcon />,
    label: 'Account',
  },
  {
    id: 'projectform',
    path: '/apifactory/project',
    icon: <Description />,
    label: 'Project',
  },
];
export const getOnlineNavigation = (): NavigationItem[] => [
  {
    id: 'apifactory-home',
    path: '/apifactory',
    icon: <ApiIcon />,
    label: 'Apifactory Home',
  },
  {
    id: 'apiDataModelsform',
    path: '/apifactory/apiDataModels',
    icon: <DonutLargeOutlined />,
    label: 'API Data Models',
  },
  {
    id: 'apiRequestParametersform',
    path: '/apifactory/ApiRequestParameters',
    icon: <FilterHdrOutlined />,
    label: 'API Request Parameters',
  },
  {
    id: 'apiSecuritySchemesform',
    path: '/apifactory/ApiSecuritySchemes',
    icon: <Fluorescent />,
    label: 'API Security Schemes',
  },
  {
    id: 'designApiform',
    path: '/apifactory/DesignApi',
    icon: <LanOutlined />,
    label: 'Design API',
  },
  {
    id: 'apiCatalogform',
    path: '/apifactory/ApiCatalog',
    icon: <PlayForWork />,
    label: 'API Catalog',
  },
];
export const getApplicationNavigation = (
  appId: string,
  isDownloadable: boolean
): NavigationItem[] => {
  const navItems: NavigationItem[] = [
    {
      id: 'components',
      path: `/app/${appId}/`,
      icon: <WidgetsIcon />,
      label: 'Components',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
  ];
  const navItemsWithVC: NavigationItem[] = [
    {
      id: 'file-changes',
      path: `/app/${appId}/changes`,
      icon: <ChangesIcon />,
      label: 'File changes',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
    {
      id: 'build-pipeline',
      path: `/app/${appId}/build-pipelines`,
      icon: <CorporateFareIcon />,
      label: 'Build Pipelines',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
    {
      id: 'release-pipeline',
      path: `/app/${appId}/release-pipelines`,
      icon: <HandymanIcon />,
      label: 'Release Pipelines',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
    {
      id: 'settings',
      path: `/app/${appId}/settings`,
      icon: <SettingsIcon />,
      label: 'Settings',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
  ];
  if (isDownloadable === undefined || isDownloadable) return navItems;
  return navItems.concat(navItemsWithVC);
};

export const getComponentNavigation = (
  appId: string,
  componentId: string,
  isDownloadable: boolean,
  isApiBuilder?: boolean
): NavigationItem[] => {
  let navItems: NavigationItem[] = [
    {
      id: 'details',
      path: `/app/${appId}/component/${componentId}`,
      icon: <HomeIcon />,
      label: 'Component Details',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
    {
      id: 'entitydesigner',
      path: `app/${appId}/component/${componentId}/entity-designer`,
      icon: <DesignServicesIcon />,
      label: 'Entity Designer',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
    {
      id: 'functions',
      path: `/app/${appId}/component/${componentId}/functions`,
      icon: <WidgetsIcon />,
      label: 'Functions',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
  ];

  const navItemsWithVC = [
    {
      id: 'build-pipeline',
      path: `app/${appId}/component/${componentId}/build-pipelines`,
      icon: <CorporateFareIcon />,
      label: 'Build Pipelines',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
    {
      id: 'release-pipeline',
      path: `app/${appId}/component/${componentId}/release-pipelines`,
      icon: <HandymanIcon />,
      label: 'Release Pipelines',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
  ];

  if (!isDownloadable) navItems = navItems.concat(navItemsWithVC);

  const navItemsWithAPIBuilder: NavigationItem[] = [
    {
      id: 'apibuilder',
      path: `app/${appId}/component/${componentId}/apibuilder/summary`,
      icon: <Layers />,
      label: 'API Builder',
      permissions: [PermissionEnum.VIEW_APP_OPS, PermissionEnum.VIEW_ALL_APP_OPS],
    },
  ];
  console.log('Api Builder :', isApiBuilder);
  if (isApiBuilder === undefined || !isApiBuilder) return navItems;
  else {
    navItems.splice(3, 0, ...navItemsWithAPIBuilder);
  }
  return navItems;
};
