export class EndpointResponseParameters {
  statusCode: string;
  statusDescription: string;
  outputFormat: string;
  outputDataType: string;
  outputDataModelId: string;
  isReposeinArrayFormat: boolean;
  constructor(
    statusCode: string,
    statusDescription: string,
    outputFormat: string,
    outputDataType: string,
    outputDataModelId: string,
    isReposeinArrayFormat: boolean
  ) {
    this.statusCode = statusCode;
    this.statusDescription = statusDescription;
    this.outputFormat = outputFormat;
    this.outputDataType = outputDataType;
    this.outputDataModelId = outputDataModelId;
    this.isReposeinArrayFormat = isReposeinArrayFormat;
  }
}
