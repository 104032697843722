import { IPublicClientApplication } from '@azure/msal-browser';
import { Services } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'iam';

class userRequestService extends RequestHandler {
  endpath = 'user-request';

  getAll(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/`);
  }

  getRequestById(requestId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/${requestId}`);
  }

  getRequestByTenant(tenantId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/tenant/${tenantId}`);
  }

  updateUserRequest(request: any, instance: IPublicClientApplication) {
    return this.requestHandler(instance).patch(`/${this.endpath}/${request.id}`, request);
  }

  postUserRequest(request: any, instance: IPublicClientApplication) {
    return this.requestHandler(instance).post(`/${this.endpath}/`, request);
  }

  deleteUserRequest(requestId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).delete(`/${this.endpath}/${requestId}`);
  }

  rejectRequest(requestId: any, instance: IPublicClientApplication, userRequest: any) {
    return this.requestHandler(instance).patch(
      `/${this.endpath}/status/reject/${requestId}`,
      userRequest
    );
  }

  approveRequest(requestId: any, instance: IPublicClientApplication, userRequest: any) {
    return this.requestHandler(instance).patch(
      `/${this.endpath}/status/approve/${requestId}`,
      userRequest
    );
  }
  updateProjectRoles(requestId: string, instance: IPublicClientApplication, projData: any) {
    return this.requestHandler(instance).patch(
      `/${this.endpath}/updateProjRoles/${requestId}`,
      projData
    );
  }

  findProjectTableData(requestId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/getTableData/${requestId}`);
  }

  getRequestByEmail(email: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/email/${email}`);
  }
}
export default new userRequestService(apiServiceName);
