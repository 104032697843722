import { createSlice } from '@reduxjs/toolkit';
import {
  getAzureWebAppList,
  getComponentPipelineConfig,
  getApplicationPipelineConfig,
} from '../actions/pipelines.actions';
import {
  IApplicationPipelineConfig,
  IComponentPipelineConfig,
  CreatedBy,
  ContainerRegistry,
  Deployment,
  Pipeline,
} from 'app/models';

type InitialPipelineState = {
  applicationPipelineConfig: IApplicationPipelineConfig;
  componentPipelineConfig: IComponentPipelineConfig;
  isDeploymentsAdded: boolean;
  isEnvironmentsAdded: boolean;
  appServices: string[];
  loading: boolean;
};

const initialComponentPipelineConfig: IComponentPipelineConfig = {
  _id: '',
  componentId: '',
  appId: '',
  buildPipelines: [],
  buildEnabled: false,
  releasePipelines: {
    pipelineName: '',
    deploymentDetails: [],
  },
  releaseEnabled: false,
};

const initialApplicationPipelineConfig: IApplicationPipelineConfig = {
  _id: '',
  appId: '',
  createddate: new Date(),
  createdby: {} as CreatedBy,
  pipeline: {} as Pipeline,
  containerRegistry: {} as ContainerRegistry,
  deployment: {} as Deployment,
  environment: [],
};

const initialState: InitialPipelineState = {
  applicationPipelineConfig: initialApplicationPipelineConfig,
  componentPipelineConfig: initialComponentPipelineConfig,
  appServices: [],
  loading: false,
  isDeploymentsAdded: false,
  isEnvironmentsAdded: false,
};

const componentSlice = createSlice({
  name: 'componentSlice',
  initialState,
  reducers: {
    clearPipelineStore: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationPipelineConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicationPipelineConfig.fulfilled, (state, action) => {
        if (action.payload) {
          state.applicationPipelineConfig = action.payload;
          state.isDeploymentsAdded = !!action.payload.deployment.subscriptionId;
          state.isEnvironmentsAdded = action.payload.environment.length > 0;
        }
        state.loading = false;
      })
      .addCase(getComponentPipelineConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getComponentPipelineConfig.fulfilled, (state, action) => {
        state.componentPipelineConfig = action.payload ?? initialComponentPipelineConfig;
        state.loading = false;
      })
      .addCase(getAzureWebAppList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAzureWebAppList.fulfilled, (state, action) => {
        state.appServices = action.payload;
        state.loading = false;
      });
  },
});

export const { clearPipelineStore } = componentSlice.actions;

export default componentSlice.reducer;
