import { IPublicClientApplication } from '@azure/msal-browser';
import { RequestHandler } from 'libs/request-handler';
import { GetCodeSpaceUrlDTO, CodespaceStatusResponseDTO } from 'app/dtos';
import { AxiosRequestConfig } from 'axios';

const apiServiceName = 'app-designer';

class CodespaceService extends RequestHandler {
  endpoint = 'app';

  async getCodeSpaceUrl(instance: IPublicClientApplication, appid: string) {
    const headers = { applicationid: appid };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<string | GetCodeSpaceUrlDTO>(
      `/${this.endpoint}/getCodespaceUrl/${appid}`,
      config
    );
  }

  async getStatus(instance: IPublicClientApplication, instanceId: string, appid: string) {
    const headers = { applicationid: appid };
    const config: AxiosRequestConfig = { headers };
    return await this.requestHandler(instance).get<CodespaceStatusResponseDTO>(
      `/${this.endpoint}/codespace/status/${instanceId}`,
      config
    );
  }
}

export default new CodespaceService(apiServiceName);
