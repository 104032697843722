import { Suspense } from 'react';
import { CssBaseline, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'app/store';
import { Outlet } from 'react-router-dom';
import { Dialog, Header, Message, SideNavBar, Wrapper, InProcessDialog } from './components';

function Layout() {
  const { mode } = useSelector((state) => state.layoutSlice.theme);
  const theme = createTheme({
    palette: {
      mode,
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  });

  return (
    <Wrapper>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <SideNavBar />
        <section id="layout__container">
          <Suspense fallback={<Fallback />}>
            <Outlet />
          </Suspense>
        </section>
        <Dialog />
        <InProcessDialog />
        <Message />
      </ThemeProvider>
    </Wrapper>
  );
}

const Fallback = () => (
  <div className="w-full h-[80vh] grid place-content-center">
    <CircularProgress />
  </div>
);

export default Layout;
