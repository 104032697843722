import { useEffect, useState } from 'react';
import { getOnlineNavigation } from 'app/configs/navigation.config';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import { FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { Button } from '@mui/material';
import { HTTPBasicAuth } from 'app/models/apiSecurity/httpbasic.model';
import { HTTPBearerAuth } from 'app/models/apiSecurity/httpBearer.model';
import { apiKey } from 'app/models/apiSecurity/apiKey.model';
import { openIDConnect } from 'app/models/apiSecurity/openIDConnect.model';
import { oAuth } from 'app/models/apiSecurity/oAuth.model';
import { APISecurity } from 'app/models/apiSecurity/apiSecurity.model';
import { showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'app/store';
import { useMsal } from '@azure/msal-react';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';

const ApiSecuritySchemes = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'DesignOnline - Api Security Schemes ',
      url: `/apifactory/ApiSecuritySchemes`,
    },
  ];
  const dispatch = useDispatch();

  const initialFormData = {
    securitySchemeName: '',
    securityType: '',
    apiKeyName: '',
    flowType: '',
    authUrl: '',
    refreshUrl: '',
    tokenUrl: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const [displayApiKey, setDisplayApiKey] = useState(false);
  const [displayFlowType, setDisplayFlowType] = useState(false);

  const [displayAuthUrl, setDisplayAuthUrl] = useState(false);
  const [displayRefreshUrl, setDisplayRefreshUrl] = useState(false);
  const [displayTokenUrl, setDisplayTokenUrl] = useState(false);

  const [apiSecurityList, setApiSecurityList] = useState<APISecurity[]>([]);
  const [basicAuthList, setBasicAuthList] = useState<HTTPBasicAuth[]>([]);
  const [bearerAuthList, setBearerAuthList] = useState<HTTPBearerAuth[]>([]);
  const [apiKeyList, setApiKeyList] = useState<apiKey[]>([]);
  const [oAuthList, setOAuthList] = useState<oAuth[]>([]);
  const [openIDList, setOpenIDList] = useState<openIDConnect[]>([]);

  const [apiSecurityFormDataErrors, setApiSecurityFormDataErrors] = useState({
    securitySchemeName: '',
    securityType: '',
    apiKeyName: '',
    flowType: '',
    authUrl: '',
    refreshUrl: '',
    tokenUrl: '',
  });

  const handleSecuritySchemeNameChange = (event: any) => {
    setFormData({
      ...formData,
      securitySchemeName: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      securitySchemeName: '',
    });
  };
  const handleSecurityTypeChange = (event: any) => {
    setFormData({
      ...formData,
      securityType: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      securityType: '',
    });
  };
  const handleApiKeyNameChange = (event: any) => {
    setFormData({
      ...formData,
      apiKeyName: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      apiKeyName: '',
    });
  };
  const handleFlowTypeChange = (event: any) => {
    setFormData({
      ...formData,
      flowType: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      flowType: '',
    });
  };
  const handleAuthUrlChange = (event: any) => {
    setFormData({
      ...formData,
      authUrl: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      authUrl: '',
    });
  };
  const handleRefreshUrlChange = (event: any) => {
    setFormData({
      ...formData,
      refreshUrl: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      refreshUrl: '',
    });
  };
  const handleTokenUrlChange = (event: any) => {
    setFormData({
      ...formData,
      tokenUrl: event.target.value,
    });
    setApiSecurityFormDataErrors({
      ...apiSecurityFormDataErrors,
      tokenUrl: '',
    });
  };

  const onChangeSecurityType = (e: any) => {
    console.log('Security Type Value : ' + e.target.value);
    const securityType = e.target.value;
    setDisplayApiKey(false);
    setDisplayFlowType(false);
    setDisplayAuthUrl(false);
    setDisplayRefreshUrl(false);
    setDisplayTokenUrl(false);
    if (securityType === 'apiKey') {
      setDisplayApiKey(true);
    }
    if (securityType === 'oauth') {
      setDisplayFlowType(true);
    }
  };

  const onChangeFlowType = (e: any) => {
    console.log('Flow Type Value : ' + e.target.value);
    const flowType = e.target.value;
    setDisplayAuthUrl(false);
    setDisplayRefreshUrl(false);
    setDisplayTokenUrl(false);
    if (flowType === 'authorizationCode') {
      setDisplayAuthUrl(true);
      setDisplayRefreshUrl(true);
      setDisplayTokenUrl(true);
    }
    if (flowType === 'implicit') {
      setDisplayAuthUrl(true);
      setDisplayRefreshUrl(true);
    }
    if (flowType === 'password') {
      setDisplayRefreshUrl(true);
      setDisplayTokenUrl(true);
    }
    if (flowType === 'clientCredentials') {
      setDisplayRefreshUrl(true);
      setDisplayTokenUrl(true);
    }
  };

  const saveSecurityData = () => {
    console.log('Save Security Data : ');

    if (validateAPISecurityForm()) {
      // Declare local variables to hold updated lists
      let updatedBasicAuthList = [...basicAuthList];
      let updatedBearerAuthList = [...bearerAuthList];
      let updatedApiKeyList = [...apiKeyList];
      let updatedOAuthList = [...oAuthList];
      let updatedOpenIDList = [...openIDList];
      let updatedApiSecurityList = [...apiSecurityList];

      if (formData.securityType === 'httpBasicAuthentication') {
        updatedBasicAuthList = [...basicAuthList, new HTTPBasicAuth(formData.securitySchemeName)];
      } else if (formData.securityType === 'httpBearerAuthentication') {
        updatedBearerAuthList = [
          ...bearerAuthList,
          new HTTPBearerAuth(formData.securitySchemeName),
        ];
      } else if (formData.securityType === 'apiKey') {
        updatedApiKeyList = [
          ...apiKeyList,
          new apiKey(formData.securitySchemeName, formData.apiKeyName),
        ];
      } else if (formData.securityType === 'openIDconnect') {
        updatedOpenIDList = [...openIDList, new openIDConnect()];
      } else if (formData.securityType === 'oauth') {
        updatedOAuthList = [
          ...oAuthList,
          new oAuth(
            formData.securitySchemeName,
            formData.flowType,
            formData.authUrl,
            formData.refreshUrl,
            formData.tokenUrl
          ),
        ];
      }

      // Use the callback form of set functions to ensure that they capture the most recent changes
      setBasicAuthList(updatedBasicAuthList);
      setBearerAuthList(updatedBearerAuthList);
      setApiKeyList(updatedApiKeyList);
      setOAuthList(updatedOAuthList);
      setOpenIDList(updatedOpenIDList);

      updatedApiSecurityList = [
        ...apiSecurityList,
        new APISecurity(
          updatedBasicAuthList,
          updatedBearerAuthList,
          updatedApiKeyList,
          updatedOAuthList,
          updatedOpenIDList
        ),
      ];
      setApiSecurityList(updatedApiSecurityList);
      console.log(apiSecurityList);
      sessionStorage.setItem('securitySchemes', JSON.stringify(updatedApiSecurityList));
      dispatch(showSuccessMessage('Security Data saved successfully'));
      setFormData(initialFormData);
      setDisplayApiKey(false);
      setDisplayFlowType(false);
      setDisplayAuthUrl(false);
      setDisplayRefreshUrl(false);
      setDisplayTokenUrl(false);
    }
  };

  const validateAPISecurityForm = () => {
    let valid = true;

    const newErrors = {
      securitySchemeName: '',
      securityType: '',
      apiKeyName: '',
      flowType: '',
      authUrl: '',
      refreshUrl: '',
      tokenUrl: '',
    };

    if (formData.securitySchemeName.trim() === '') {
      newErrors.securitySchemeName = 'Security Scheme Name is required';
      valid = false;
    }
    if (formData.securityType.trim() === '') {
      newErrors.securityType = 'Security Type is required';
      valid = false;
    }
    if (formData.securityType.trim() === 'apiKey' && formData.apiKeyName.trim() === '') {
      newErrors.apiKeyName = 'Api Key Name is required';
      valid = false;
    }
    if (formData.securityType.trim() === 'oauth' && formData.flowType.trim() === '') {
      newErrors.flowType = 'Flow Type is required';
      valid = false;
    }
    if (formData.flowType.trim() === 'authorizationCode') {
      if (formData.authUrl.trim() === '') {
        newErrors.authUrl = 'Authorization Url is required';
        valid = false;
      } else if (!validateUrl(formData.authUrl.trim())) {
        newErrors.authUrl = 'Authorization Url is not valid';
        valid = false;
      }
      if (formData.refreshUrl.trim() === '') {
        newErrors.refreshUrl = 'Refresh Url is required';
        valid = false;
      } else if (!validateUrl(formData.refreshUrl.trim())) {
        newErrors.refreshUrl = 'Refresh Url is not valid';
        valid = false;
      }
      if (formData.tokenUrl.trim() === '') {
        newErrors.tokenUrl = 'Token Url is required';
        valid = false;
      } else if (!validateUrl(formData.tokenUrl.trim())) {
        newErrors.tokenUrl = 'Token Url is not valid';
        valid = false;
      }
    }
    if (formData.flowType.trim() === 'implicit') {
      if (formData.authUrl.trim() === '') {
        newErrors.authUrl = 'Authorization Url is required';
        valid = false;
      } else if (!validateUrl(formData.authUrl.trim())) {
        newErrors.authUrl = 'Authorization Url is not valid';
        valid = false;
      }
      if (formData.refreshUrl.trim() === '') {
        newErrors.refreshUrl = 'Refresh Url is required';
        valid = false;
      } else if (!validateUrl(formData.refreshUrl.trim())) {
        newErrors.refreshUrl = 'Refresh Url is not valid';
        valid = false;
      }
    }
    if (
      formData.flowType.trim() === 'password' ||
      formData.flowType.trim() === 'clientCredentials'
    ) {
      if (formData.refreshUrl.trim() === '') {
        newErrors.refreshUrl = 'Refresh Url is required';
        valid = false;
      } else if (!validateUrl(formData.refreshUrl.trim())) {
        newErrors.refreshUrl = 'Refresh Url is not valid';
        valid = false;
      }
      if (formData.tokenUrl.trim() === '') {
        newErrors.tokenUrl = 'Token Url is required';
        valid = false;
      } else if (!validateUrl(formData.tokenUrl.trim())) {
        newErrors.tokenUrl = 'Token Url is not valid';
        valid = false;
      }
    }
    setApiSecurityFormDataErrors(newErrors);

    return valid;
  };

  const validateUrl = (input: string) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(input);
  };

  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    authorizedNavigation(getOnlineNavigation());
  }, []);

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      {/* Header */}
      <div className="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div className="flex-1 min-w-0">
          {/* Title */}
          <div className="mt-2">
            <h4 className="text-1xl md:text-2xl font-bold tracking-tight leading-7 sm:leading-10 truncate">
              Security Screen
            </h4>
          </div>
        </div>
      </div>

      {/* Main */}
      <div className="flex-auto p-6 sm:p-10">
        <div className="max-w-xl">
          <form className="flex flex-col">
            {/* Text Field */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="textField" required>
                  Security Scheme Name
                </InputLabel>
                <Input
                  id="textField"
                  type="text"
                  inputProps={{ maxLength: 30 }}
                  required
                  value={formData.securitySchemeName}
                  onChange={handleSecuritySchemeNameChange}
                />
              </FormControl>
              <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.securitySchemeName}</span>
            </div>
            {/* Dropdown */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="dropdown" required>
                  Security Type
                </InputLabel>
                <Select
                  id="dropdown"
                  label="Security Type"
                  required
                  value={formData.securityType}
                  onChange={(event) => {
                    onChangeSecurityType(event);
                    handleSecurityTypeChange(event);
                  }}
                >
                  <MenuItem value="httpBasicAuthentication">HTTP-Basic Authentication</MenuItem>
                  <MenuItem value="httpBearerAuthentication">HTTP-Bearer Authentication</MenuItem>
                  <MenuItem value="apiKey">API Key</MenuItem>
                  <MenuItem value="openIDconnect">OpenID Connect</MenuItem>
                  <MenuItem value="oauth">OAuth</MenuItem>
                </Select>
              </FormControl>
              <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.securityType}</span>
            </div>

            {/* Text Field */}
            {displayApiKey && (
              <div className="flex flex-col mb-6">
                <FormControl className="mb-2">
                  <InputLabel htmlFor="textField" required>
                    API Key Name
                  </InputLabel>
                  <Input
                    id="textField"
                    type="text"
                    inputProps={{ maxLength: 30 }}
                    required
                    value={formData.apiKeyName}
                    onChange={handleApiKeyNameChange}
                  />
                </FormControl>
                <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.apiKeyName}</span>
              </div>
            )}

            {/* Dropdown */}
            {displayFlowType && (
              <div className="flex flex-col mb-6">
                <FormControl className="mb-2">
                  <InputLabel htmlFor="dropdown" required>
                    Flow Type
                  </InputLabel>
                  <Select
                    id="dropdown"
                    label="Flow Type"
                    required
                    value={formData.flowType}
                    onChange={(event) => {
                      onChangeFlowType(event);
                      handleFlowTypeChange(event);
                    }}
                  >
                    <MenuItem value="authorizationCode">Authorization Code</MenuItem>
                    <MenuItem value="implicit">Implicit</MenuItem>
                    <MenuItem value="password">Password</MenuItem>
                    <MenuItem value="clientCredentials">Client Credentials</MenuItem>
                  </Select>
                </FormControl>
                <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.flowType}</span>
              </div>
            )}

            {/* Text Field */}
            {displayAuthUrl && (
              <div className="flex flex-col mb-6">
                <FormControl className="mb-2">
                  <InputLabel htmlFor="textField" required>
                    Authorization URL
                  </InputLabel>
                  <Input
                    id="textField"
                    type="url"
                    inputProps={{ maxLength: 50 }}
                    required
                    value={formData.authUrl}
                    onChange={handleAuthUrlChange}
                  />
                </FormControl>
                <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.authUrl}</span>
              </div>
            )}

            {/* Text Field */}
            {displayRefreshUrl && (
              <div className="flex flex-col mb-6">
                <FormControl className="mb-2">
                  <InputLabel htmlFor="textField" required>
                    Refresh URL
                  </InputLabel>
                  <Input
                    id="textField"
                    type="url"
                    inputProps={{ maxLength: 50 }}
                    required
                    value={formData.refreshUrl}
                    onChange={handleRefreshUrlChange}
                  />
                </FormControl>
                <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.refreshUrl}</span>
              </div>
            )}

            {/* Text Field */}
            {displayTokenUrl && (
              <div className="flex flex-col mb-6">
                <FormControl className="mb-2">
                  <InputLabel htmlFor="textField" required>
                    Token URL
                  </InputLabel>
                  <Input
                    id="textField"
                    type="url"
                    inputProps={{ maxLength: 50 }}
                    required
                    value={formData.tokenUrl}
                    onChange={handleTokenUrlChange}
                  />
                </FormControl>
                <span style={{ color: 'red' }}>{apiSecurityFormDataErrors.tokenUrl}</span>
              </div>
            )}

            {/* Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={saveSecurityData}
              type="button"
              className="mt-4 w-[120px]"
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApiSecuritySchemes;
