import { IPublicClientApplication } from '@azure/msal-browser';
import { RequestHandler } from 'libs/request-handler';
import { AxiosRequestConfig } from 'axios';

const apiServiceName = 'app-designer';

class ComponentDesignSpecService extends RequestHandler {
  endpath = 'componentdesignspec';

  convertOpenAPIToDesignSpec(instance: IPublicClientApplication, openApiSpec: any, appId: string) {
    const headers = { applicationid: appId, 'Content-Type': 'multipart/form-data' };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `${this.endpath}/convertOpenAPIToDesignSpec`,
      openApiSpec,
      config
    );
  }

  generateCodeFromDesignSpec(
    instance: IPublicClientApplication,
    layer: string,
    designSpec: any,
    componentId?: string,
    appId?: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `${this.endpath}/generateCodeFromDesignSpec/${componentId}/${layer}`,
      designSpec,
      config
    );
  }

  getComponentDesignSpecByComponentId(
    instance: IPublicClientApplication,
    componentId?: string,
    appId?: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(`${this.endpath}/component/${componentId}`, config);
  }

  saveDesignSpecByType(
    instance: IPublicClientApplication,
    layer: string,
    designSpec: any,
    componentId?: string,
    appId?: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).put(
      `${this.endpath}/saveDesignSpecByType/${componentId}/${layer}`,
      designSpec,
      config
    );
  }
}

export default new ComponentDesignSpecService(apiServiceName);
