import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchProjectsList } from 'app/main/dashboard/store/actions/projects.actions';
import {
  fetchCurrentUserEffortSaved,
  fetchCurrentUserMraCount,
  fetchUser,
  getLocationsByTenant,
  checkTenantByDomain,
} from 'app/main/dashboard/store/actions/users.actions';
import { IUser, ITenant } from 'app/models';
import { ProjAppPermissions } from 'app/models/common/user-context.model';

type InitialStateType = {
  user: IUser;
  tenant: ITenant;
  permissions: string[];
  projAppPermissions: ProjAppPermissions;
  mraCount?: number;
  totalEffortSaved?: number;
  tenantData?: any;
  locationData?: any;
};

const initialState: InitialStateType = {
  user: {} as IUser,
  tenant: {} as ITenant,
  permissions: [],
  projAppPermissions: {} as ProjAppPermissions,
  mraCount: 0,
  totalEffortSaved: 0,
  tenantData: { result: {}, status: 0 },
  locationData: { locations: [], data: [], status: 0 },
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<InitialStateType>) => {
      state.user = action.payload.user;
      state.tenant = action.payload.tenant;
      state.permissions = action.payload.permissions;
      state.projAppPermissions = action.payload.projAppPermissions;
    },
    setProjAppPermissions: (state, action: PayloadAction<ProjAppPermissions>) => {
      state.projAppPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(fetchCurrentUserMraCount.fulfilled, (state, action) => {
        state.mraCount = action.payload.data;
      })
      .addCase(fetchCurrentUserEffortSaved.fulfilled, (state, actions) => {
        state.totalEffortSaved = actions.payload.data;
      })
      .addCase(getLocationsByTenant.fulfilled, (state, action) => {
        state.locationData.locations = action.payload.locations;
        state.locationData.data = action.payload.data;
        state.locationData.status = action.payload.status;
      })
      .addCase(checkTenantByDomain.fulfilled, (state, actions: any) => {
        state.tenantData.result = actions.payload.result;
        state.tenantData.status = actions.payload.status;
      });
  },
});

export const { setAuth, setProjAppPermissions } = authSlice.actions;

export default authSlice.reducer;
