import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateBranchDTO, CreateEnvironmentDTO, LoadRepositoryBranchesDTO } from 'app/dtos';
import { IApplicationPipelineConfig } from 'app/models';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import applicationUserService from 'app/services/app-designer/application-user.service';
import applicationService from 'app/services/app-designer/application.service';
import githubService from 'app/services/app-designer/github.service';
import applicationPipelineConfigService from 'app/services/pipeline/application-pipeline-config.service';

export const fetchRepositories = createAsyncThunk(
  'settings/fetchRepositories',
  async (data: WithInstance<{ token: string }>) => {
    try {
      const response = await applicationUserService.loadRepositories(data.instance, data.token);
      return response.data.map((repository: any) => ({
        id: repository.id,
        name: repository.name,
        url: repository.url,
        fullName: repository.full_name,
        owner: repository.owner.login,
      }));
    } catch {
      return [];
    }
  }
);

export const getApplicationPipelineConfig = createAsyncThunk(
  'applicationStore/getApplicationPipelineConfig',
  async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
    try {
      const response = await applicationPipelineConfigService.getPipelineConfig(
        data.instance,
        data.appId
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot load pipeline details'));
      return {} as IApplicationPipelineConfig;
    }
  }
);

export const fetchRepositoryBranches = createAsyncThunk(
  'settings/fetchRepositoryBranches',
  async (data: WithInstance<{ payload: LoadRepositoryBranchesDTO }>) => {
    try {
      const response = await applicationUserService.loadRepositoryBranches(
        data.instance,
        data.payload
      );
      return response.data.map((branch: any) => branch.name);
    } catch {
      return [];
    }
  }
);

export const fetchOrganizations = createAsyncThunk(
  'settings/fetchOrganizations',
  async ({ instance, token }: WithInstance<{ token: string }>) => {
    try {
      const res = await githubService.listOrganizations(instance, token);
      return res.data.map((org) => org.login);
    } catch (error) {
      return [];
    }
  }
);

export const createEnvironment = createAsyncThunk(
  'settings/createEnvironment',
  async (data: ActionPayload<{ appId: string; envData: CreateEnvironmentDTO }>, thunkAPI) => {
    try {
      await applicationPipelineConfigService.createEnvs(data.instance, data.appId, data.envData);
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('error'));
      return error;
    }
  }
);

export const createBranch = createAsyncThunk(
  'settings/createBranch',
  async (data: ActionPayload<{ appId: string; branchdata: CreateBranchDTO }>, thunkAPI) => {
    try {
      await applicationService.createBranch(data.instance, data.appId, data.branchdata);
      thunkAPI.dispatch(showSuccessMessage('Branch creation successful'));
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('error'));
      return error;
    }
  }
);
