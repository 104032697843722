import { SetStateAction, useEffect, useState, useCallback } from 'react';
import { getOnlineNavigation } from 'app/configs/navigation.config';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import ApiService from 'app/services/fileupload.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, LinearProgress, Toolbar, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ViewApiDataModel from './ViewApiDataModel';
import { AxiosError } from 'axios';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import useConfirmDialog from '../hooks/useConfirmDialog';
import { useDispatch } from 'app/store';
import AddEditApiDataModel from './AddEditApiDataModel';
import { Card, CardContent, Typography } from '@mui/material';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import { useMsal } from '@azure/msal-react';
import { openDialog } from 'app/layouts/store/slices/dialog.slice';
import Loading from 'app/components/Loading';

const ApiDataModels = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'DesignOnline - Api Data Models ',
      url: `/apifactory/apiDataModels`,
    },
  ];
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const showConfirmDialog = useConfirmDialog();
  const [loading, setLoading] = useState(false);

  const openDataModelDialog = (data: any) => () => {
    console.log(data);
    dispatch(
      openDialog({
        title: 'APIDataModel',
        children: <ViewApiDataModel data={data} />,
      })
    );
  };

  function openAddDataModelDialog() {
    dispatch(
      openDialog({
        open: true,
        children: <AddEditApiDataModel onCloseDialog={closeDialog} />,
        title: 'Add Data Model',
      })
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'DataModelName',
      headerName: 'Model Name',
      type: 'string',
      minWidth: 400,
    },
    {
      field: 'ModelType',
      headerName: 'Model Type',
      minWidth: 400,
      type: 'string',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 400,
      sortable: false,
      renderCell(params: GridRenderCellParams<any>) {
        return (
          <div className="flex gap-2">
            <Tooltip title="Edit model">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    openDialog({
                      open: true,
                      children: (
                        <AddEditApiDataModel modelData={params.row} onCloseDialog={closeDialog} />
                      ),
                      title: 'Edit Data Model',
                    })
                  );
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Model">
              <IconButton
                onClick={() => handleDelete(params.row.DataModelID, params.row.DataModelName)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Model">
              <IconButton onClick={openDataModelDialog(params.row)} size="large">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const closeDialog = () => {
    getDatModelList();
  };

  const handleDelete = useCallback(async (dataModelId: string, dataModelName: string) => {
    console.log('dataModelId : ' + dataModelId);
    const confirm = await showConfirmDialog(`Are you sure to delete dataModel ${dataModelName}?`);
    if (confirm) {
      try {
        setLoading(true);
        const response = await ApiService.deleteDataModelById(instance, dataModelId);
        if (response.status === 200) {
          dispatch(showSuccessMessage('Data Model deleted successfully'));
          getDatModelList();
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          dispatch(showErrorMessage('Unable to delete the Data Model, please try after sometime.'));
        }
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    authorizedNavigation(getOnlineNavigation());
    getDatModelList();
  }, []);

  const getDatModelList = () => {
    const projectId = sessionStorage.getItem('currentUserProjectID');
    setLoading(true);
    ApiService.getDataModelbyProjectId(instance, projectId)
      .then((response) => {
        console.log('response', response?.data?.data);
        setRecords(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRowId = (record: { DataModelID: any }) => record.DataModelID;

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Loading loading={loading} />
      {/* Header */}
      <div className="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div className="flex-1 min-w-0">
          {/* Title */}
          <div className="mt-2">
            <h4 className="text-1xl md:text-2xl font-bold tracking-tight leading-7 sm:leading-10 truncate">
              Data Models
            </h4>
          </div>
        </div>
      </div>

      {/* Main */}
      <main className="flex flex-col gap-2 w-full">
        <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Add Model" arrow>
            <IconButton onClick={openAddDataModelDialog} size="large">
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Box sx={{ height: 'auto', width: '100%' }}>
          <DataGrid
            autoHeight
            rows={records}
            columns={columns}
            pageSize={pageSize}
            disableSelectionOnClick
            onPageSizeChange={(newPageSize: SetStateAction<number>) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            getRowId={getRowId}
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </Box>
      </main>
    </div>
  );
};

export default ApiDataModels;
