import { NotFound, RedirectHere, NotAuthenticated, UnAuthorized } from 'app/components';
import Layout from 'app/layouts/Layout';
import applicationRoutes from 'app/main/application/application.config';
import authRoutes from 'app/main/auth/auth.config';
import dashboardRoutes from '../main/dashboard/dashboard.config';
import { Navigate, RouteObject } from 'react-router-dom';
import apifactoryHomeRoutes from 'app/main/apifactory/apifactory.config';

const routes: RouteObject[] = [
  { path: '/redirect-here', element: <RedirectHere /> },
  { path: '/401', element: <NotAuthenticated /> },
  { path: '/403', element: <UnAuthorized /> },
  { path: '/404', element: <NotFound /> },
  { path: '*', element: <Navigate to="/404" /> },

  ...authRoutes,
  {
    element: <Layout />,
    children: [...dashboardRoutes, ...applicationRoutes, ...apifactoryHomeRoutes],
  },
];

export default routes;
