import { setNavigation } from 'app/layouts/store/slices/navigation.slice';
import { NavigationItem } from 'app/models';
import { useSelector } from 'app/store';
import { intersection } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const useNavAuthorization = (): ((
  navigation: NavigationItem[],
  projIdParam?: string,
  appIdParam?: string
) => boolean) => {
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector((store) => store.authSlice.user.superadmin);
  const tenantPermissions = useSelector((store) => store.authSlice.permissions);
  const userProjAppPermissions = useSelector((store) => store.authSlice.projAppPermissions);

  const app = useSelector((store) => store.applicationSlice.application.application);

  function checkIfNavPermissionGranted(
    navigation: NavigationItem[],
    projIdParam?: string,
    appIdParam?: string
  ) {
    if (isSuperAdmin) {
      dispatch(setNavigation(navigation));
      return true;
    }

    // console.log(projIdParam, appIdParam);

    const grantedNavigation: NavigationItem[] = [];

    for (const navItem of navigation) {
      if (navItem.permissions) {
        const permissionGranted = intersection(navItem.permissions, tenantPermissions);

        if (permissionGranted?.length) {
          grantedNavigation.push(navItem);
          continue;
        }

        if (projIdParam !== undefined) {
          const projPermissionGranted = intersection(
            navItem.permissions,
            userProjAppPermissions[projIdParam].permissions
          );
          if (projPermissionGranted?.length) {
            grantedNavigation.push(navItem);
            continue;
          }

          if (appIdParam !== undefined) {
            const appPermissionGranted = intersection(
              navItem.permissions,
              userProjAppPermissions[projIdParam].applications[appIdParam].permissions
            );
            if (appPermissionGranted?.length) {
              grantedNavigation.push(navItem);
              continue;
            }
          }
        }
      } else {
        grantedNavigation.push(navItem);
        continue;
      }
    }

    dispatch(setNavigation(grantedNavigation));
    return true;
  }

  return checkIfNavPermissionGranted;
};

export default useNavAuthorization;
