import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { showErrorMessage } from 'app/layouts/store/slices/message.slice';
import userService from 'app/services/iam/user.service';
import tenantManagementService from 'app/services/management/tenant-management.service';
import { useDispatch } from 'app/store';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SplashScreen } from 'app/components';
import AuthContext from './AuthContext';
import { setAuth } from './store/auth.slice';
import projectService from 'app/services/management/project.service';
import applicationService from 'app/services/app-designer/application.service';
import { ProjAppPermissions } from 'app/models/common/user-context.model';
import { Message } from 'app/layouts/components';

function AuthProvider({ children }: PropsWithChildren) {
  const { instance, accounts, inProgress } = useMsal();
  const account = accounts[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const logoutUser = (message: string) => () => {
    dispatch(showErrorMessage(message));
    return undefined;
  };

  const validateUser = async () => {
    try {
      const response = await userService.validateUser(instance);
      return response.data;
    } catch (err) {
      logoutUser('Error fetching User, logging you out!');
    }
  };

  const getTenantDetails = async () => {
    try {
      const response = await tenantManagementService.getMyTenantDetails(instance);
      return response.data;
    } catch {
      logoutUser('Error fetching tenant. Logging you out');
    }
  };

  const getProjRolesAndPermissions = async (userId: string) => {
    try {
      const response = await projectService.getProjIdAndRolesByUserId(instance, userId);
      return response.data;
    } catch {
      return {};
    }
  };

  const getAppRolesAndPermissions = async (userId: string) => {
    try {
      const response = await applicationService.getAppIdAndRolesByUserId(instance, userId);
      return response.data;
    } catch {
      return {};
    }
  };

  const checkIfSubscribed = async () => {
    setLoading(true);
    setIsAuthenticated(false);

    const user = await validateUser();
    // console.log(user);
    if (user === undefined) {
      dispatch(
        showErrorMessage('You are an unauthorised user. Please register to the application.')
      );
      setLoading(false);
      setIsAuthenticated(false);
      localStorage.clear();
      return;
    }
    if (!user.isactive) {
      dispatch(
        showErrorMessage('Your account has been locked. Please Contact your IT admin for support')
      );
      setLoading(false);
      setIsAuthenticated(false);
      localStorage.clear();
      return;
    }

    sessionStorage.setItem('currentUserEmail', user.email);
    // @ts-expect-error null error
    if (JSON.parse(localStorage.getItem('loggedIn'))) {
      await userService.updateLoginCountAndTime(instance, user.email);
      localStorage.setItem('loggedIn', 'false');
    }
    // const permissions = await getUserRolePermissions();
    const permissions: string[] = [];
    user.roles.forEach((role) => {
      role.permissions.forEach((permission) => permissions.push(permission.name));
    });

    const tenant = await getTenantDetails();
    if (!tenant) return;

    const projRolesPermissions = await getProjRolesAndPermissions(user._id);
    const appRolesPermissions = await getAppRolesAndPermissions(user._id);

    const projAppPermissions: ProjAppPermissions = {} as ProjAppPermissions;

    for (const projId of Object.keys(projRolesPermissions)) {
      projAppPermissions[projId] = {
        roles: projRolesPermissions[projId].roles,
        permissions: projRolesPermissions[projId].permissions,
        applications: appRolesPermissions[projId] ? appRolesPermissions[projId] : {},
      };
    }

    dispatch(setAuth({ user, permissions, tenant, projAppPermissions }));
    setLoading(false);
    setIsAuthenticated(true);
  };

  const autoLogin = async (error: unknown) => {
    const status =
      error instanceof InteractionRequiredAuthError && account.idTokenClaims?.preferred_username;
    if (!status) {
      instance.logout();
      return;
    }
    const email = account.idTokenClaims?.preferred_username;
    const domain = email?.split('@').pop();
    if (!domain) {
      instance.logout();
      return;
    }

    try {
      const { data: tenant } = await tenantManagementService.getTenantDetailsByDomain(
        instance,
        domain
      );
      await instance.loginPopup({
        domainHint: tenant.idp,
        loginHint: email,
        prompt: 'login',
        scopes: [],
      });
      // navigate('/');
    } catch {
      navigate('/404');
    }
  };

  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent({ account, scopes: [] }).then(checkIfSubscribed).catch(autoLogin);
    }
    if (!account && inProgress === InteractionStatus.None) {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [account, inProgress, instance]);

  if (loading) return <SplashScreen />;

  return (
    <>
      <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
      <Message />
    </>
  );
}

export default AuthProvider;
