import { useCallback, useEffect, useState } from 'react';
import { getOnlineNavigation } from 'app/configs/navigation.config';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import Loading from 'app/components/Loading';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from 'app/services/fileupload.service';
import { API } from 'app/models/apiCatalogModels/api-catalog.types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AxiosError } from 'axios';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import useConfirmDialog from '../hooks/useConfirmDialog';
import { useDispatch } from 'app/store';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import { useMsal } from '@azure/msal-react';

const ApiCatalogCard = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'DesignOnline - Api Catalog ',
      url: `/apifactory/ApiCatalog`,
    },
  ];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filteredApis, setFilteredApis] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [projectId, setProjectId] = useState<any>();
  const [projectNames, setProjectNames] = useState<{ projectId: number; projectName: string }[]>(
    []
  );
  const [noProjectsAssigned, setNoProjectsAssigned] = useState(false);
  const showConfirmDialog = useConfirmDialog();

  const handleDelete = useCallback(async (api: API) => {
    console.log('API ID : ' + api.ApiID);
    const confirm = await showConfirmDialog('Are you sure want to delete the API!');
    if (confirm) {
      try {
        setLoading(true);
        const response = await ApiService.deleteApiDetailsByApiId(instance, api);
        if (response.status === 200) {
          dispatch(showSuccessMessage('The API has been deleted.'));
          getAPIsByProjectId();
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          dispatch(showErrorMessage('Unable to delete the API, please try after sometime.'));
        }
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const getAPIsByProjectId = () => {
    const projectId = sessionStorage.getItem('currentUserProjectID');
    setLoading(true);
    ApiService.getAPIsByProjectId(instance, projectId)
      .then((apis: any) => {
        console.log('Success');
        setFilteredApis(apis?.data?.data);
      })
      .catch((errorInstance: any) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const authorizedNavigation = useNavAuthorization();

  const currentEmail = sessionStorage.getItem('currentUserEmail');

  const getProjectNamesforUsers = (currentEmail: any) => {
    setLoading(true);
    ApiService.getProjectNamesforUsers(instance, currentEmail)
      .then((response) => {
        setProjectNames(response?.data?.data);
        const data = response?.data?.data;
        if (data.length === 0) {
          setNoProjectsAssigned(true);
        }
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const projObj = projectNames.find((c) => c.projectId === projectId);

    if (projObj) {
      sessionStorage.setItem('currentUserProject', projObj.projectName);
      sessionStorage.setItem('currentUserProjectID', String(projObj.projectId));
      handleClose();
      getAPIsByProjectId();
    } else {
      console.log('Project not found');
    }
  };

  const handleProjectNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setProjectId(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const popupOpenedBefore = localStorage.getItem('popupOpened');
    authorizedNavigation(getOnlineNavigation());
    if (popupOpenedBefore === 'false' || popupOpenedBefore === '' || popupOpenedBefore === null) {
      getProjectNamesforUsers(currentEmail);
      handleClickOpen();
      localStorage.setItem('popupOpened', 'true');
    }
    if (popupOpenedBefore === 'true') {
      getAPIsByProjectId();
    }
  }, [dispatch]);

  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/apifactory');
  };

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Loading loading={loading} />

      {noProjectsAssigned && (
        <Dialog open={true} fullWidth maxWidth="sm">
          <DialogContent>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                No Projects Assigned, Please check with the Admin.
              </Typography>
              <Button onClick={navigateToHome} variant="contained" color="primary">
                Back To Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {!noProjectsAssigned && (
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                Select Project
              </Typography>
              <FormControl style={{ width: '100%', marginBottom: '10px' }}>
                <InputLabel> Project Name</InputLabel>

                <Select
                  value={projectId}
                  label="Project Name"
                  onChange={handleProjectNameChange}
                  required
                >
                  {projectNames.map((project) => (
                    <MenuItem key={project.projectId} value={project.projectId}>
                      {project.projectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button type="submit" variant="contained" color="primary">
                Ok
              </Button>
              <Button onClick={navigateToHome} style={{ marginLeft: '20px' }} color="primary">
                Back To Home
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      )}

      <div className="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div className="flex-1 min-w-0">
          <div className="mt-2">
            <h4 className="text-1xl md:text-2xl font-bold tracking-tight leading-7 sm:leading-10 truncate">
              Api Catalog
            </h4>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
        {/* Ensure each API is rendered in a separate small square box (Card) */}
        {filteredApis.map((api: API) => (
          <Card
            key={api.ApiID}
            className="flex flex-col items-center p-4 transition-all duration-75 hover:shadow-4"
          >
            <CardContent className="flex flex-col w-full gap-2 text-center">
              <Typography className="text-md font-bold" title={api.ApiName}>
                {api.ApiName}
              </Typography>
              <Typography className="text-xs" color="text.secondary">
                {api.Description}
              </Typography>
            </CardContent>

            <div className="flex gap-2 mt-auto">
              <Tooltip title="Edit Api">
                <Link to={`/apifactory/DesignApi/${api.ApiID}`}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="Delete Api">
                <IconButton onClick={() => handleDelete(api)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Api">
                <Link to={`/apifactory/apiEditorDesign/${api.ApiID}`}>
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ApiCatalogCard;
