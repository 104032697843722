import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import LogInPage from './pages/LogInPage';
import RegistrationPage from './pages/RegisterPage';

const authRoutes: RouteObject[] = [
  {
    path: '/login',
    element: <LogInPage />,
  },
  {
    path: '/register',
    element: <RegistrationPage />,
  },
];

export default authRoutes;
