import { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  Typography,
  FormControl,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';
import ApiService from 'app/services/fileupload.service';
import { useMsal } from '@azure/msal-react';
import useDialog from '../hooks/popup';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'app/store';
import { closeDialog } from 'app/layouts/store/slices/dialog.slice';
import Loading from 'app/components/Loading';

function RemoveUser({ formdata, onCloseDialog }: any) {
  const { instance } = useMsal();
  const [emails, setEmails] = useState<string[]>([]);
  const [projectId] = useState(formdata.projectId || '');
  const [error, setError] = useState<string | null>(null);
  const { showDialog } = useDialog();
  const dispatch = useDispatch();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const getUsersForProject = () => {
    ApiService.getUsersForProject(instance, projectId)
      .then((response) => {
        console.log('response', response.data.data);
        setEmails(response.data.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      });
  };

  useEffect(() => {
    getUsersForProject();
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<any>, value: string | string[]) => {
    setSelectedEmails(value as string[]);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedEmails.length === 0) {
      setError('Please select at least one email');
      return;
    }
    const form = {
      email: selectedEmails,
      projectId,
    };

    setLoading(true);
    ApiService.deleteUserProject(instance,form)
      .then((response) => {
        const responseData = JSON.parse(response.data);
        if (responseData.code >= 200 && responseData.code < 210) {
          console.log('response', responseData);

          showDialog('User(s) Removed successfully ');
          dispatch(showSuccessMessage('User(s) Removed successfully  '));
          dispatch(closeDialog());
          onCloseDialog();
        } else {
          // Non-2xx status code

          setError(`Request failed with status code ${responseData.code}`);
          showDialog(
            'Something went wrong , Please try after sometime',
            <p>{responseData.message}</p>
          );
          dispatch(showErrorMessage(<p>{responseData.message}</p>));
        }
      })

      .catch((errorInstance) => {
        console.error('Error:', errorInstance);

        let errorMessage: string;
        if (errorInstance.message.includes('Network Error')) {
          errorMessage = 'Something went wrong , Please try after sometime.';
        } else {
          errorMessage = `Request failed: ${errorInstance.responseData.message}`;
        }

        setError(errorInstance);
        showDialog('Error', <p>{errorMessage}</p>);
        dispatch(showErrorMessage(<p>{errorMessage}</p>));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DialogContent>
      <form onSubmit={handleFormSubmit}>
        <Typography variant="h5">Remove User(s)</Typography>
        <br></br>
        <Loading loading={loading} />
        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <Autocomplete
            multiple
            id="email-search"
            options={emails}
            value={selectedEmails}
            onChange={(event: React.ChangeEvent<any>, newValue: string | string[]) =>
              handleEmailChange(event, newValue)
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Search and Select Email Id" variant="outlined" />
            )}
          />
        </FormControl>

        <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '420px' }}>
            Submit
          </Button>
        </div>
      </form>{' '}
    </DialogContent>
  );
}

export default RemoveUser;
