import { combineReducers } from '@reduxjs/toolkit';
import appsReducer from './slices/apps.slice';
import usersReducer from './slices/users.slice';
import projsReducer from './slices/projects.slice';
import homeReducer from './slices/home.slice';
import mraReducer from './slices/mras.slice';

const reducer = combineReducers({
  home: homeReducer,
  apps: appsReducer,
  projects: projsReducer,
  mra: mraReducer,
  users: usersReducer,
});

export default reducer;
