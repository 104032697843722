import { combineReducers } from '@reduxjs/toolkit';
import theme from './slices/theme.slice';
import dialog from './slices/dialog.slice';
import message from './slices/message.slice';
import navigation from './slices/navigation.slice';
import IPDialogSlice from './slices/in-process-dialog.slice';

const reducer = combineReducers({
  theme,
  dialog,
  message,
  navigation,
  IPDialogSlice,
});

export default reducer;
