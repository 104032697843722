import { Box } from '@mui/system';
import BGImage from 'assets/LandingPageBG.svg';
import styled from 'styled-components';

const RegistrationWrapper = styled(Box)`
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;

  section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${BGImage});
    background-size: cover;

    .container {
      position: relative;
      max-width: 40rem;
      border-radius: 8px;
      padding: 30px;
      margin: 1.2rem;
      box-shadow: 20px 20px 50px -10px rgba(0, 0, 0, 0.8);
      background-color: #fff;
    }

    .container header {
      font-weight: 700;
      color: rgb(46, 46, 46);
      font-size: 3rem;
      /* 
      &::after {
        content: '.';
        font-weight: 600;
        color: #0066ff;
      } */
    }
    .container header h1::after {
      content: '.';
      font-weight: 600;
      color: #0066ff;
    }
    /* .container header::before{
      content:"";
      position:absolute;
      left:0;
      bottom: -2px;
      height:5px;
      width:50px;
      background-color: #011f2f;
      border-radius:3px;
    } */
  }
`;
export default RegistrationWrapper;
