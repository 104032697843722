import { useEffect, useState } from 'react';
import { getOnlineNavigation } from 'app/configs/navigation.config';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import ApiService from 'app/services/fileupload.service';
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { EndpointRequestParameters } from 'app/models/designApi/endpointRequestParameters.model';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { EndpointResponseParameters } from 'app/models/designApi/endpointResponseParametres.model';
import { APIEndpoints } from 'app/models/designApi/apiEndpoints.model';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'app/store';
import { useMsal } from '@azure/msal-react';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import { APIMetaInfo } from 'app/models/designApi/apiMetaInfo.model';
import { useParams, Link, useLocation } from 'react-router-dom';
import useDialog from '../hooks/popup';
// @ts-ignore
import SwaggerEditorBundle from './swagger-editor-dist/swagger-editor-bundle';
// @ts-ignore
import SwaggerEditorStandalonePreset from './swagger-editor-dist/swagger-editor-standalone-preset';
import './swagger-editor-dist/swagger-editor.css';
import DialogBox from './popupdialog';
import Loading from 'app/components/Loading';

const DesignApiEndpoints = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'DesignOnline - Design API ',
      url: `/apifactory/designApi`,
    },
  ];

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const { dialogContent, isDialogOpen, showDialog, handleCloseDialog } = useDialog();

  const steps = ['API Basic Information', 'API Endpoints/Paths', 'API Specification'];
  const verticalSteps = ['Add Request Parameters', 'Add a Response'];

  const [activeStep, setActiveStep] = useState(0);
  const [verticalActiveStep, setVerticalActiveStep] = useState(0);

  const [isResponseArrayChecked, setIsResponseArrayChecked] = useState(true);

  const [apiBasicFormData, setApiBasicFormData] = useState({
    apiTitle: '',
    apiDescription: '',
    url: '',
    auth: '',
  });
  const [apiBasicFormDataErrors, setApiBasicFormDataErrors] = useState({
    apiTitle: '',
    apiDescription: '',
    url: '',
    auth: '',
  });

  const [apiEndpointsFormData, setApiEndpointsFormData] = useState({
    endpointName: '',
    path: '',
    operation: '',
    description: '',
    endpointAuthentication: '',
    summary: '',
    tag: '',
  });
  const [apiRequestFormData, setApiRequestFormData] = useState({
    parameterType: '',
    parameterName: '',
  });
  const [apiResponseFormData, setApiResponseFormData] = useState({
    responseStatusCode: '',
    responseDescription: '',
    outputFormat: '',
    outputType: '',
    outputDataModelId: '',
    responseInArray: isResponseArrayChecked,
  });

  const [apiEndpointsFormDataErrors, setApiEndpointsFormDataErrors] = useState({
    endpointName: '',
    path: '',
    operation: '',
    description: '',
    endpointAuthentication: '',
    summary: '',
    tag: '',
  });

  const [dataModels, setDataModels] = useState<any>();
  const [securityList, setSecurityList] = useState([]);
  const [apiAuthSet, setApiAuthSet] = useState(new Set());
  const [parameterNames, setParameterNames] = useState<{ label: string; value: string }[]>([]);

  const [requestTempIndex, setRequestTempIndex] = useState(undefined);
  const [requestParametersList, setRequestParametersList] = useState<EndpointRequestParameters[]>(
    []
  );
  const [requestRows, setRequestRows] = useState<any>([]);
  const [displayDataModel, setDisplayDataModel] = useState(false);

  const [responseParametersList, setResponseParametersList] = useState<
    EndpointResponseParameters[]
  >([]);
  const [responseRow, setResponseRow] = useState<any>([]);
  const [responseTempIndex, setResponseTempIndex] = useState(undefined);

  const [apiEndpoints, setApiEndpoints] = useState<APIEndpoints[]>([]);
  const [endpointRow, setEndpointRow] = useState<any>([]);
  const [endPointTempIndex, setEndPointTempIndex] = useState(undefined);

  const [apiId, setApiId] = useState<any>(null);
  const { editApiId } = useParams<{ editApiId: string }>();
  const [apiSpecification, setApiSpecification] = useState('');

  let queryParameterList: any = [];
  let pathParameterList: any = [];
  let headerParameterList: any = [];
  let requestBodyList: any = [];

  const handleVerticalNext = () => {
    setVerticalActiveStep((prevVerticalActiveStep) => prevVerticalActiveStep + 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goHorizontalStepTo = (index: any) => {
    setActiveStep(index);
  };

  const goVerticalStepTo = (index: any) => {
    setVerticalActiveStep(index);
  };

  const handleApiTitleChange = (event: any) => {
    setApiBasicFormData({
      ...apiBasicFormData,
      apiTitle: event.target.value,
    });
    setApiBasicFormDataErrors({
      ...apiBasicFormDataErrors,
      apiTitle: '',
    });
  };
  const handleApiDescriptionChange = (event: any) => {
    setApiBasicFormData({
      ...apiBasicFormData,
      apiDescription: event.target.value,
    });
    setApiBasicFormDataErrors({
      ...apiBasicFormDataErrors,
      apiDescription: '',
    });
  };
  const handleServerUrlChange = (event: any) => {
    setApiBasicFormData({
      ...apiBasicFormData,
      url: event.target.value,
    });
    setApiBasicFormDataErrors({
      ...apiBasicFormDataErrors,
      url: '',
    });
  };
  const handleAuthenticationChange = (event: any) => {
    setApiBasicFormData({
      ...apiBasicFormData,
      auth: event.target.value,
    });
  };

  const validateUrl = (input: string) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(input);
  };

  const displayDefineParameterOnParameterTypeChange = (selectedValue: any) => {
    const secondDropdownOptions = [];

    if (selectedValue === 'Query Parameters') {
      const queryParametersString = sessionStorage.getItem('QueryParameters');
      const queryParameterList = queryParametersString ? queryParametersString.split(',') : [];
      for (const i in queryParameterList) {
        const key = queryParameterList[i].replace('[', '').replace(']', '').replace(/"/g, '');
        console.log(key);
        const element = { label: key, value: key };
        secondDropdownOptions.push(element);
      }
    } else if (selectedValue === 'URI Path Segment') {
      const pathParameterString = sessionStorage.getItem('PathParameters');
      const pathParameterList = pathParameterString ? pathParameterString.split(',') : [];
      for (const i in pathParameterList) {
        const key = pathParameterList[i].replace('[', '').replace(']', '').replace(/"/g, '');
        console.log(key);
        const element = { label: key, value: key };
        secondDropdownOptions.push(element);
      }
    } else if (selectedValue === 'HTTP Header') {
      const headerParameterString = sessionStorage.getItem('HeaderParameters');
      const headerParameterList = headerParameterString ? headerParameterString.split(',') : [];
      for (const i in headerParameterList) {
        const key = headerParameterList[i].replace('[', '').replace(']', '').replace(/"/g, '');
        console.log(key);
        const element = { label: key, value: key };
        secondDropdownOptions.push(element);
      }
    } else if (selectedValue === 'requestBody') {
      const requestBodyString = sessionStorage.getItem('RequestBody');
      const requestBodyList = requestBodyString ? requestBodyString.split(',') : [];
      for (const i in requestBodyList) {
        const key = requestBodyList[i].replace('[', '').replace(']', '').replace(/"/g, '');
        console.log(key);
        const element = { label: key, value: key };
        secondDropdownOptions.push(element);
      }
    } else {
      const element = { label: 'dropdown', value: 'option' };
      secondDropdownOptions.push(element);
    }
    setParameterNames(secondDropdownOptions);
  };

  const saveRequestParameter = () => {
    console.log('saveRequestParameter is called');
    const parameter = new EndpointRequestParameters(
      apiRequestFormData.parameterName,
      apiRequestFormData.parameterType
    );

    const newRow = {
      parameterType: apiRequestFormData.parameterType,
      parameterName: apiRequestFormData.parameterName,
    };

    if (newRow.parameterName && newRow.parameterType) {
      if (requestTempIndex === undefined) {
        setRequestParametersList([...requestParametersList, parameter]);
        setRequestRows([...requestRows, newRow]);
      } else {
        const updatedRequestParametersList = [...requestParametersList];
        updatedRequestParametersList[requestTempIndex] = parameter;
        setRequestParametersList(updatedRequestParametersList);
        const updatedRows = [...requestRows];
        updatedRows[requestTempIndex] = newRow;
        setRequestRows(updatedRows);

        setRequestTempIndex(undefined);
      }
      console.log(requestParametersList);

      setApiRequestFormData({
        ...apiRequestFormData,
        parameterType: '',
        parameterName: '',
      });
    }
  };

  const saveResponseParameters = () => {
    console.log('saveResponseParameters is called');
    if (!isNumeric(apiResponseFormData.responseStatusCode)) {
      return;
    }

    const response = new EndpointResponseParameters(
      apiResponseFormData.responseStatusCode,
      apiResponseFormData.responseDescription,
      apiResponseFormData.outputFormat,
      apiResponseFormData.outputType,
      apiResponseFormData.outputDataModelId,
      apiResponseFormData.responseInArray
    );

    const newRow = {
      responseStatusCode: apiResponseFormData.responseStatusCode,
      responseDescription: apiResponseFormData.responseDescription,
      outputFormat: apiResponseFormData.outputFormat,
      outputType: apiResponseFormData.outputType,
      outputDataModelId: apiResponseFormData.outputDataModelId,
    };

    if (
      newRow.responseStatusCode &&
      newRow.responseDescription &&
      newRow.outputFormat &&
      newRow.outputType
    ) {
      if (responseTempIndex === undefined) {
        setResponseParametersList([...responseParametersList, response]);
        setResponseRow([...responseRow, newRow]);
      } else {
        const updatedResponseParametersList = [...responseParametersList];
        updatedResponseParametersList[responseTempIndex] = response;
        setResponseParametersList(updatedResponseParametersList);

        const updatedRows = [...responseRow];
        updatedRows[responseTempIndex] = newRow;
        setResponseRow(updatedRows);

        setResponseTempIndex(undefined);
      }
      console.log(responseParametersList);

      setApiResponseFormData({
        ...apiResponseFormData,
        responseStatusCode: '',
        responseDescription: '',
        outputFormat: '',
        outputType: '',
        outputDataModelId: '',
        responseInArray: isResponseArrayChecked,
      });
      setDisplayDataModel(false);
    }
  };

  const validateAPIRequestForm = () => {
    let valid = true;

    const newErrors = {
      endpointName: '',
      path: '',
      operation: '',
      description: '',
      endpointAuthentication: '',
      summary: '',
      tag: '',
    };

    if (apiEndpointsFormData.endpointName.trim() === '') {
      newErrors.endpointName = 'Endpoint Name is required';
      valid = false;
    }
    if (apiEndpointsFormData.path.trim() === '') {
      newErrors.path = 'Path is required';
      valid = false;
    }
    if (apiEndpointsFormData.operation.trim() === '') {
      newErrors.operation = 'Operation is required';
      valid = false;
    }
    setApiEndpointsFormDataErrors(newErrors);

    return valid;
  };

  const saveEndPoint = () => {
    console.log('Save Endpoint Method Called!');
    if (validateAPIRequestForm()) {
      const endpoint = new APIEndpoints(
        apiEndpointsFormData.endpointName,
        apiEndpointsFormData.path,
        apiEndpointsFormData.operation,
        apiEndpointsFormData.description,
        apiEndpointsFormData.endpointAuthentication,
        apiEndpointsFormData.summary,
        apiEndpointsFormData.tag,
        requestParametersList,
        responseParametersList
      );

      setRequestParametersList([]);
      setResponseParametersList([]);

      const newRow = {
        endpointName: apiEndpointsFormData.endpointName,
        path: apiEndpointsFormData.path,
        description: apiEndpointsFormData.description,
        summary: apiEndpointsFormData.summary,
        operation: apiEndpointsFormData.operation,
        tag: apiEndpointsFormData.tag,
        endpointAuthentication: apiEndpointsFormData.endpointAuthentication,
      };
      if (newRow.endpointName && newRow.path && newRow.operation) {
        if (endPointTempIndex === undefined) {
          setApiEndpoints([...apiEndpoints, endpoint]);
          setEndpointRow([...endpointRow, newRow]);
        } else {
          apiEndpoints[endPointTempIndex] = endpoint;
          endpointRow[endPointTempIndex] = newRow;
          setEndPointTempIndex(undefined);
        }
        console.log('APIEndpoints : ' + apiEndpoints);
        setRequestRows([]);
        setResponseRow([]);
        dispatch(showSuccessMessage("Endpoint '" + newRow.endpointName + "' saved successfully."));
      }
      setApiEndpointsFormData({
        ...apiEndpointsFormData,
        endpointName: '',
        path: '',
        operation: '',
        description: '',
        endpointAuthentication: '',
        summary: '',
        tag: '',
      });
    }
  };

  const editRequestRows = (row: any) => {
    console.log('edit request :::', row);
    const index = requestRows.indexOf(row);
    setRequestTempIndex(index);
    setApiRequestFormData({
      ...apiRequestFormData,
      parameterType: row.parameterType,
      parameterName: row.parameterName,
    });

    displayDefineParameterOnParameterTypeChange(row.parameterType);
  };

  const deleteRequestRows = (row: any) => {
    console.log('delete request :::', row);
    const index = requestRows.indexOf(row);
    if (index !== -1) {
      const parameterName = row.parameterName;

      // Remove parameterName from requestParametersList
      setRequestParametersList((prevList) => {
        const updatedList = prevList.filter((param) => param.parameterName !== parameterName);
        return updatedList;
      });

      // Remove the row from the requestRows state
      setRequestRows((prevRows: any) => {
        const updatedRows = [...prevRows];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };

  const editResponseRows = (row: any) => {
    console.log('edit response :::', row);
    const index = responseRow.indexOf(row);
    setResponseTempIndex(index);
    setApiResponseFormData({
      ...apiResponseFormData,
      responseStatusCode: row.responseStatusCode,
      responseDescription: row.responseDescription,
      outputFormat: row.outputFormat,
      outputType: row.outputType,
      outputDataModelId: row.outputDataModelId,
      responseInArray: row.responseInArray,
    });
    if (row.outputType === 'Object') {
      setDisplayDataModel(true);
    } else {
      setDisplayDataModel(false);
    }
  };

  const deleteResponseRows = (row: any) => {
    console.log('delete response :::', row);
    const index = responseRow.indexOf(row);
    if (index !== -1) {
      const responseStatusCode = row.responseStatusCode;

      // Remove statusCode from responseParametersList
      setResponseParametersList((prevList) => {
        const updatedList = prevList.filter((param) => param.statusCode !== responseStatusCode);
        return updatedList;
      });

      // Remove the row from the responseRow state
      setResponseRow((prevRows: any) => {
        const updatedRows = [...prevRows];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };

  const editEndpoint = (row: any) => {
    console.log('edit endpoint :::', row);
    const index = endpointRow.indexOf(row);
    setEndPointTempIndex(index);
    setApiEndpointsFormData({
      ...apiEndpointsFormData,
      endpointName: row.endpointName,
      path: row.path,
      operation: row.operation,
      description: row.description,
      endpointAuthentication: row.endpointAuthentication,
      summary: row.summary,
      tag: row.tag,
    });
    setRequestRows([]);
    setResponseRow([]);
    setRequestParametersList([]);
    setResponseParametersList([]);

    // Update request parameters state
    if (apiEndpoints[index]?.requestParametersList?.length > 0) {
      for (const request of apiEndpoints[index].requestParametersList) {
        const requestRow = {
          parameterType: request.parameterType,
          parameterName: request.parameterName,
        };
        setRequestRows((prevRows: any) => [...prevRows, requestRow]);
        const requestParameter = new EndpointRequestParameters(
          requestRow.parameterName,
          requestRow.parameterType
        );
        setRequestParametersList((prevList) => [...prevList, requestParameter]);
      }
    }
    // Update response parameters state
    if (apiEndpoints[index]?.responseParametersList?.length > 0) {
      for (const responses of apiEndpoints[index].responseParametersList) {
        const responseRow = {
          responseStatusCode: responses.statusCode,
          responseDescription: responses.statusDescription,
          outputFormat: responses.outputFormat,
          outputType: responses.outputDataType,
          outputDataModelId: responses.outputDataModelId,
          responseInArray: responses.isReposeinArrayFormat,
        };
        setResponseRow((prevRows2: any) => [...prevRows2, responseRow]);
        const response = new EndpointResponseParameters(
          responseRow.responseStatusCode,
          responseRow.responseDescription,
          responseRow.outputFormat,
          responseRow.outputType,
          responseRow.outputDataModelId,
          responseRow.responseInArray
        );
        setResponseParametersList((prevList) => [...prevList, response]);
      }
    }
  };

  const deleteEndpoint = (row: any) => {
    console.log('delete endpoint :::', row);
    const index = endpointRow.indexOf(row);
    if (index !== -1) {
      const endpointName = row.endpointName;

      // Remove endpointName from apiEndpoints
      setApiEndpoints((prevEndpoints) => {
        const updatedEndpoints = prevEndpoints.filter(
          (endpoint) => endpoint.endpointName !== endpointName
        );
        return updatedEndpoints;
      });

      // Remove the row from the rows1 state
      setEndpointRow((prevRows: any) => {
        const updatedRows = [...prevRows];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };

  const saveBasicInfo = () => {
    if (validateAPIBasicForm()) {
      const { apiTitle, apiDescription, url, auth } = apiBasicFormData;

      if (apiTitle !== null && apiTitle !== '') {
        sessionStorage.setItem('ApiTitle', apiTitle);
      }
      if (apiDescription !== null && apiDescription !== '') {
        sessionStorage.setItem('APIDescription', apiDescription);
      }
      if (url !== null && url !== '') {
        sessionStorage.setItem('APIUrl', url);
      }
      if (auth !== null && auth !== '') {
        sessionStorage.setItem('APIAuth', auth);
      }

      handleNext();
    }
  };

  const saveAPI = () => {
    console.log('Save API Called!');
    console.log('Endpoint Length - ' + apiEndpoints.length);

    if (apiEndpoints.length > 0) {
      let apiMetaInfo;
      const sessionApiRequestParametersList = sessionStorage.getItem('requestParametersList');
      const apiRequestParametersList =
        sessionApiRequestParametersList !== null ? JSON.parse(sessionApiRequestParametersList) : [];
      const sessionProjectId = sessionStorage.getItem('currentUserProjectID');
      const projectId = sessionProjectId !== null ? JSON.parse(sessionProjectId) : null;
      console.log('apiRequestParametersList - ' + apiRequestParametersList);
      if (editApiId) {
        apiMetaInfo = new APIMetaInfo(
          apiBasicFormData.apiTitle,
          apiBasicFormData.apiDescription,
          projectId,
          parseInt(editApiId),
          apiBasicFormData.url,
          apiBasicFormData.auth,
          apiEndpoints,
          apiRequestParametersList,
          securityList
        );
      } else {
        apiMetaInfo = new APIMetaInfo(
          apiBasicFormData.apiTitle,
          apiBasicFormData.apiDescription,
          projectId,
          apiId,
          apiBasicFormData.url,
          apiBasicFormData.auth,
          apiEndpoints,
          apiRequestParametersList,
          securityList
        );
      }

      console.log('apiMetaInfo - ', apiMetaInfo);
      const apiMetaInfoString = JSON.stringify(apiMetaInfo);
      console.log('apiMetaInfoString - ', apiMetaInfoString);
      setLoading(true);
      ApiService.saveAPIDetails(instance, apiMetaInfoString)
        .then((apiDetailResponse: any) => {
          console.log('Api Detail Response', apiDetailResponse);
          setApiId(apiDetailResponse?.data?.data?.apiId);
          setApiSpecification(apiDetailResponse?.data?.data?.apiSpecification);
          showSwaggerEditor(apiDetailResponse?.data?.data?.apiSpecification);
          dispatch(showSuccessMessage('API saved successfully.'));
        })
        .catch((errorInstance) => {
          console.error('Error:', errorInstance);
        })
        .finally(() => {
          setLoading(false);
        });
      return true;
    } else {
      dispatch(
        showErrorMessage(
          'At least one endpoint should be added in the design before saving and creating specification.'
        )
      );
      return false;
    }
  };

  const showSwaggerEditor = (apiSpecification: any) => {
    const editor = SwaggerEditorBundle({
      dom_id: '#swagger-editor',
      layout: 'StandaloneLayout',
      presets: [SwaggerEditorStandalonePreset],
      swagger2GeneratorUrl: null,
      oas3GeneratorUrl: null,
      url: '',
    });

    // Remove right pane
    const el = document.getElementsByClassName('Pane vertical Pane2  ');
    el[0].remove();

    // Remove toolbar
    const e2 = document.getElementsByClassName('swagger-editor-standalone');
    e2[0].remove();

    // // Reduce Editor width
    // const splitPaneVertical = document.getElementsByClassName("SplitPane vertical ")[0] as HTMLElement;
    // splitPaneVertical.style.position = "relative";
    // splitPaneVertical.style.width = "100%";

    console.log('Before updating Spec in editor - ' + apiSpecification);
    editor.specActions.updateSpec(apiSpecification);
  };

  // Need to Work
  const pushSpecificationToRepo = () => {
    const projectId = sessionStorage.getItem('currentUserProjectID');
    const yamlInputData = apiSpecification;
    const apiName = apiBasicFormData.apiTitle;
    setLoading(true);
    ApiService.pushAPISpecificationtorepo(instance, yamlInputData, apiName, projectId)
      .then((response) => {
        console.log('response', response?.data);
        if (response.status >= 200 && response.status < 300) {
          const outputLink = response?.data?.url;
          showDialog(
            'OAS File uploaded to Git repository. Please find the link below:',
            <a href={outputLink} target="_blank" rel="noopener noreferrer">
              Specification Link{' '}
            </a>
          );
        } else {
          // Non-2xx status code
          showDialog('Something went wrong , Please try after sometime', <p>{response.status}</p>);
        }
      })
      .catch((errorInstance: any) => {
        console.error('Error:', errorInstance);
        let errorMessage: string;
        if (errorInstance.message.includes('Network Error')) {
          errorMessage = 'Something went wrong , Please try after sometime.';
        } else {
          errorMessage = `Request failed: ${errorInstance.message}`;
        }
        showDialog('Error', <p>{errorMessage}</p>);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const displayDataModelOnDataTypeChange = (dataType: string) => {
    console.log(dataType);
    if (dataType === 'Object') {
      setDisplayDataModel(true);
    } else {
      setDisplayDataModel(false);
    }
  };

  const handleResponseArrayCheckboxChange = () => {
    setIsResponseArrayChecked(!isResponseArrayChecked);
    setApiResponseFormData({
      ...apiResponseFormData,
      responseInArray: !isResponseArrayChecked,
    });
  };

  // Validate API Basic Information
  const validateAPIBasicForm = () => {
    let valid = true;

    const newErrors = {
      apiTitle: '',
      apiDescription: '',
      url: '',
      auth: '',
    };

    if (apiBasicFormData.apiTitle.trim() === '') {
      newErrors.apiTitle = 'Api Title is required';
      valid = false;
    }
    if (apiBasicFormData.apiDescription.trim() === '') {
      newErrors.apiDescription = 'Api Description is required';
      valid = false;
    }
    if (apiBasicFormData.url.trim() !== '' && !validateUrl(apiBasicFormData.url.trim())) {
      newErrors.url = 'Server url is not valid';
      valid = false;
    }

    setApiBasicFormDataErrors(newErrors);

    return valid;
  };

  const isNumeric = (value: any) => {
    return value !== undefined && value !== '' ? /^[0-9]*$/.test(value) : true;
  };

  const dispatch = useDispatch();

  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    console.log('useEffect called !');
    authorizedNavigation(getOnlineNavigation());

    // Empty the all fields Start
    setApiBasicFormData({
      ...apiBasicFormData,
      apiTitle: '',
      apiDescription: '',
      url: '',
      auth: '',
    });

    setApiEndpointsFormData({
      ...apiEndpointsFormData,
      endpointName: '',
      path: '',
      operation: '',
      description: '',
      endpointAuthentication: '',
      summary: '',
      tag: '',
    });

    setApiRequestFormData({
      ...apiRequestFormData,
      parameterType: '',
      parameterName: '',
    });

    setApiResponseFormData({
      ...apiResponseFormData,
      responseStatusCode: '',
      responseDescription: '',
      outputFormat: '',
      outputType: '',
      outputDataModelId: '',
      responseInArray: isResponseArrayChecked,
    });

    setApiAuthSet(new Set());
    setSecurityList([]);

    setRequestRows([]);
    setResponseRow([]);
    setEndpointRow([]);
    setApiEndpoints([]);
    setActiveStep(0);
    // Empty the all fields End
    setLoading(true);

    const sessionProjectId = sessionStorage.getItem('currentUserProjectID');
    ApiService.getDataModelbyProjectId(instance, sessionProjectId)
      .then((response) => {
        console.log('models data', response);
        setDataModels(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });

    const storedSecurityList = sessionStorage.getItem('securitySchemes');
    if (storedSecurityList) {
      const parsedSecurityList = JSON.parse(storedSecurityList);
      setSecurityList(parsedSecurityList);
      console.log(parsedSecurityList);
      const newApiAuthSet = new Set();
      for (const security of parsedSecurityList) {
        for (const i in security.basicAuthenticationList) {
          newApiAuthSet.add(security.basicAuthenticationList[i].authenticationName);
        }
        for (const i in security.bearerAuthenticationList) {
          newApiAuthSet.add(security.bearerAuthenticationList[i].authenticationName);
        }
      }
      setApiAuthSet(newApiAuthSet);
      console.log(newApiAuthSet);
    }

    // *****edit functionality start *****
    if (editApiId != null) {
      setLoading(true);
      ApiService.getAPIDetailsByAPIId(instance, editApiId)
        .then((response: any) => {
          const apiModeldata = response?.data;
          console.log(apiModeldata);
          for (const modeldata of apiModeldata.data) {
            const apiModelObj = modeldata.ApiModel;
            const jsonData = JSON.parse(apiModelObj);
            setApiBasicFormData({
              ...apiBasicFormData,
              apiTitle: jsonData.apiTitle,
              apiDescription: jsonData.apiDescription,
              url: jsonData.apiServerURL,
              auth: jsonData.apiAuthentication,
            });

            if (sessionStorage.getItem('QueryParameters') != null) {
              const sessionQueryParameterList = sessionStorage.getItem('QueryParameters');
              if (sessionQueryParameterList !== null) {
                queryParameterList = JSON.parse(sessionQueryParameterList);
              }
            }
            if (sessionStorage.getItem('PathParameters') != null) {
              const sessionPathParameterList = sessionStorage.getItem('PathParameters');
              if (sessionPathParameterList !== null) {
                pathParameterList = JSON.parse(sessionPathParameterList);
              }
            }
            if (sessionStorage.getItem('HeaderParameters') != null) {
              const sessionHeaderParameterList = sessionStorage.getItem('HeaderParameters');
              if (sessionHeaderParameterList !== null) {
                headerParameterList = JSON.parse(sessionHeaderParameterList);
              }
            }
            if (sessionStorage.getItem('RequestBody') != null) {
              const sessionRequestBodyList = sessionStorage.getItem('RequestBody');
              if (sessionRequestBodyList !== null) {
                requestBodyList = JSON.parse(sessionRequestBodyList);
              }
            }

            if (jsonData.apiRequestParameters != null) {
              for (const reqParam of jsonData.apiRequestParameters) {
                if (reqParam.parameterType === 'Query Parameters') {
                  queryParameterList.push(reqParam.parameterName);
                  const uniqueQueryParameterList = queryParameterList.filter(
                    (value: any, index: any, array: string | any[]) =>
                      array.indexOf(value) === index
                  );
                  const queryParametersStr = JSON.stringify(uniqueQueryParameterList);
                  sessionStorage.setItem('QueryParameters', queryParametersStr);
                } else if (reqParam.parameterType === 'URI Path Segment') {
                  pathParameterList.push(reqParam.parameterName);
                  const uniquePathParameterList = pathParameterList.filter(
                    (value: any, index: any, array: string | any[]) =>
                      array.indexOf(value) === index
                  );
                  const pathParameterstr = JSON.stringify(uniquePathParameterList);
                  sessionStorage.setItem('PathParameters', pathParameterstr);
                } else if (reqParam.parameterType === 'HTTP Header') {
                  headerParameterList.push(reqParam.parameterName);
                  const uniqueHeaderParameterList = headerParameterList.filter(
                    (value: any, index: any, array: string | any[]) =>
                      array.indexOf(value) === index
                  );
                  const headerParametersStr = JSON.stringify(uniqueHeaderParameterList);
                  sessionStorage.setItem('HeaderParameters', headerParametersStr);
                } else if (reqParam.parameterType === 'requestBody') {
                  requestBodyList.push(reqParam.parameterName);
                  const uniqueRequestBodyList = requestBodyList.filter(
                    (value: any, index: any, array: string | any[]) =>
                      array.indexOf(value) === index
                  );
                  const requestBodyStr = JSON.stringify(uniqueRequestBodyList);
                  sessionStorage.setItem('RequestBody', requestBodyStr);
                }
              }
            }

            if (jsonData.apiRequestParameters != null) {
              if (sessionStorage.getItem('requestParametersList') != null) {
                const sessionRequestParametersList =
                  sessionStorage.getItem('requestParametersList');
                if (sessionRequestParametersList !== null) {
                  const apiRequestParameters = JSON.parse(sessionRequestParametersList);
                  for (const reqParam of jsonData.apiRequestParameters) {
                    const paramPresent = apiRequestParameters.some(
                      (sessionReqParam: { parameterName: any; parameterType: any }) =>
                        reqParam.parameterName === sessionReqParam.parameterName &&
                        reqParam.parameterType === sessionReqParam.parameterType
                    );
                    if (!paramPresent) {
                      apiRequestParameters.push(reqParam);
                    }
                  }

                  sessionStorage.setItem(
                    'requestParametersList',
                    JSON.stringify(apiRequestParameters)
                  );
                }
              } else {
                sessionStorage.setItem(
                  'requestParametersList',
                  JSON.stringify(jsonData.apiRequestParameters)
                );
              }
            }

            if (jsonData.apiSecuritySchemes != null) {
              if (sessionStorage.getItem('securitySchemes') != null) {
                const securities = sessionStorage.getItem('securitySchemes');
                if (securities !== null) {
                  const sessionSecurityList = JSON.parse(securities);
                  setSecurityList(sessionSecurityList);
                  for (const secParam of jsonData.apiSecuritySchemes) {
                    for (const sessionSecParam of sessionSecurityList) {
                      if (
                        secParam.basicAuthenticationList.length > 0 &&
                        secParam.basicAuthenticationList[0].authenticationName ===
                          sessionSecParam.basicAuthenticationList[0].authenticationName
                      ) {
                        setApiAuthSet((prevNames) =>
                          new Set(prevNames).add(
                            secParam.basicAuthenticationList[0].authenticationName
                          )
                        );
                      }
                      if (
                        secParam.bearerAuthenticationList.length > 0 &&
                        secParam.bearerAuthenticationList[0].authenticationName ===
                          sessionSecParam.bearerAuthenticationList[0].authenticationName
                      ) {
                        setApiAuthSet((prevNames) =>
                          new Set(prevNames).add(
                            secParam.bearerAuthenticationList[0].authenticationName
                          )
                        );
                      }
                    }
                  }
                }
              } else {
                setSecurityList(jsonData.apiSecuritySchemes);
                const authenticationName =
                  jsonData.apiSecuritySchemes[0]?.basicAuthenticationList[0]?.authenticationName;
                if (authenticationName) {
                  setApiAuthSet((prevNames) => new Set(prevNames).add(authenticationName));
                }
              }
            }

            setEndpointRow([]);
            setApiEndpoints([]);
            if (jsonData.apiEndpoints != null) {
              for (const endPoints of jsonData.apiEndpoints) {
                const newRow = {
                  endpointName: endPoints.endpointName,
                  path: endPoints.endpointPath,
                  description: endPoints.endpointDescription,
                  summary: endPoints.endpointSummary,
                  operation: endPoints.endpointOperation,
                  tag: endPoints.endpointTag,
                  endpointAuthentication: endPoints.endpointAuthentication,
                };

                setEndpointRow((prevRows: any) => [...prevRows, newRow]);

                const endpoint = new APIEndpoints(
                  newRow.endpointName,
                  newRow.path,
                  newRow.operation,
                  newRow.description,
                  newRow.endpointAuthentication,
                  newRow.summary,
                  newRow.tag,
                  endPoints.requestParametersList,
                  endPoints.responseParametersList
                );

                setApiEndpoints((prevEndpoints) => [...prevEndpoints, endpoint]);
              }
            }
          }
        })
        .catch((errorInstance: any) => {
          console.error('Error:', errorInstance);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // *****edit functionality end *****
  }, [location.pathname]);

  const handleStepChange = (nextStep: number, currentStep: number) => {
    console.log('Next step === ' + nextStep + ' Current Step === ' + currentStep);
    if (currentStep === 0) {
      if (!validateAPIBasicForm()) {
        if (nextStep === 0) {
          setActiveStep(nextStep - 0);
        } else if (nextStep === 1) {
          setActiveStep(nextStep - 1);
        } else if (nextStep === 2) {
          setActiveStep(nextStep - 2);
        }
      } else {
        if (nextStep === 2) {
          if (apiEndpoints.length > 0) {
            saveAPI();
          } else {
            setActiveStep(nextStep - 1);
            dispatch(
              showErrorMessage(
                'At least one endpoint should be added in the design before saving and creating specification.'
              )
            );
          }
        }
      }
    } else if (currentStep === 1) {
      if (nextStep === 0) {
        setActiveStep(0);
      } else if (nextStep === 1) {
        setActiveStep(nextStep - 0);
      } else if (nextStep === 2) {
        if (apiEndpoints.length > 0) {
          saveAPI();
        } else {
          setActiveStep(nextStep - 1);
          dispatch(
            showErrorMessage(
              'At least one endpoint should be added in the design before saving and creating specification.'
            )
          );
        }
      }
    }
  };

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Loading loading={loading} />
      {/* Header */}
      <div className="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div className="flex-1 min-w-0">
          {/* Title */}
          <div className="mt-2">
            <h4 className="text-1xl md:text-2xl font-bold tracking-tight leading-7 sm:leading-10 truncate">
              Design the API basic details and Endpoints
            </h4>
          </div>
        </div>
      </div>
      <br />
      {activeStep === 1 && (
        <div className="flex justify-end">
          <Button
            className="px-8"
            mat-flat-button
            type="button"
            variant="contained"
            onClick={() => {
              if (apiEndpoints.length > 0) {
                setActiveStep(2);
              }
              saveAPI();
            }}
          >
            Save API
          </Button>
        </div>
      )}
      {/* Main */}
      <main className="w-full p-4 flex flex-col gap-4">
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((menu, index) => (
            <Step key={index}>
              <StepButton
                color="inherit"
                onClick={() => {
                  goHorizontalStepTo(index);
                  handleStepChange(index, activeStep);
                }}
              >
                {menu}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <form noValidate className="flex flex-col gap-2">
          {activeStep === 0 && (
            <div className="flex-auto p-6 sm:p-10">
              <p className="my-6 font-medium">Fill in your API basic information</p>
              <div className="max-w-xl">
                {/* Text Field */}
                <div className="flex flex-col mb-6">
                  <FormControl className="mb-2">
                    <InputLabel htmlFor="textField" required>
                      API Title
                    </InputLabel>
                    <Input
                      id="textField"
                      type="text"
                      inputProps={{ maxLength: 30 }}
                      required
                      value={apiBasicFormData.apiTitle}
                      onChange={handleApiTitleChange}
                    />
                  </FormControl>
                  <span style={{ color: 'red' }}>{apiBasicFormDataErrors.apiTitle}</span>
                </div>
                <div className="flex flex-col mb-6">
                  <FormControl className="mb-2">
                    <InputLabel htmlFor="textField" required>
                      API Description
                    </InputLabel>
                    <Input
                      id="textField"
                      type="text"
                      inputProps={{ maxLength: 50 }}
                      required
                      value={apiBasicFormData.apiDescription}
                      onChange={handleApiDescriptionChange}
                    />
                  </FormControl>
                  <span style={{ color: 'red' }}>{apiBasicFormDataErrors.apiDescription}</span>
                </div>
                <div className="flex flex-col mb-6">
                  <FormControl className="mb-2">
                    <InputLabel htmlFor="textField">Server URL</InputLabel>
                    <Input
                      id="textField"
                      type="text"
                      inputProps={{ maxLength: 50 }}
                      value={apiBasicFormData.url}
                      onChange={handleServerUrlChange}
                    />
                  </FormControl>
                  <span style={{ color: 'red' }}>{apiBasicFormDataErrors.url}</span>
                </div>
                {/* Dropdown */}
                <div className="flex flex-col mb-6">
                  <FormControl className="mb-2">
                    <InputLabel htmlFor="dropdown">Authentication</InputLabel>
                    <Select
                      id="auth"
                      label="Authentication"
                      value={apiBasicFormData.auth}
                      onChange={handleAuthenticationChange}
                    >
                      {Array.from(apiAuthSet).map((authData: any, index: number) => {
                        return (
                          <MenuItem value={authData} key={index}>
                            {authData}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="flex justify-end">
                <Button onClick={saveBasicInfo} variant="contained" type="button">
                  Next
                </Button>
              </div>
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <div>
                <fieldset style={{ border: 'solid 1px #A9B2B6', padding: '20px' }}>
                  <legend style={{ fontSize: '1.125rem', color: 'blue' }}>
                    Endpoint Information
                  </legend>
                  <div className="max-w-xl">
                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel htmlFor="textField" required>
                          Endpoint Name / Operation ID
                        </InputLabel>
                        <Input
                          id="textField"
                          type="text"
                          inputProps={{ maxLength: 50 }}
                          value={apiEndpointsFormData.endpointName}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              endpointName: e.target.value,
                            });
                            setApiEndpointsFormDataErrors({
                              ...apiEndpointsFormDataErrors,
                              endpointName: '',
                            });
                          }}
                        />
                      </FormControl>
                      <span style={{ color: 'red' }}>
                        {apiEndpointsFormDataErrors.endpointName}
                      </span>
                    </div>

                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel htmlFor="textField" required>
                          Path
                        </InputLabel>
                        <Input
                          id="textField"
                          type="text"
                          inputProps={{ maxLength: 50 }}
                          value={apiEndpointsFormData.path}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              path: e.target.value,
                            });
                            setApiEndpointsFormDataErrors({
                              ...apiEndpointsFormDataErrors,
                              path: '',
                            });
                          }}
                        />
                      </FormControl>
                      <span style={{ color: 'red' }}>{apiEndpointsFormDataErrors.path}</span>
                    </div>

                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel required>Operation Type</InputLabel>
                        <Select
                          name="operation"
                          label="Operation Type"
                          value={apiEndpointsFormData.operation}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              operation: e.target.value,
                            });
                            setApiEndpointsFormDataErrors({
                              ...apiEndpointsFormDataErrors,
                              operation: '',
                            });
                          }}
                        >
                          <MenuItem value="get">GET</MenuItem>
                          <MenuItem value="put">PUT</MenuItem>
                          <MenuItem value="POST">POST</MenuItem>
                          <MenuItem value="DELETE">DELETE</MenuItem>
                          <MenuItem value="PATCH">PATCH</MenuItem>
                          <MenuItem value="HEAD">HEAD</MenuItem>
                          <MenuItem value="TRACE">TRACE</MenuItem>
                        </Select>
                      </FormControl>
                      <span style={{ color: 'red' }}>{apiEndpointsFormDataErrors.operation}</span>
                    </div>

                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel htmlFor="textField">Description</InputLabel>
                        <Input
                          id="textField"
                          type="text"
                          inputProps={{ maxLength: 50 }}
                          value={apiEndpointsFormData.description}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              description: e.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel htmlFor="dropdown">Endpoint Authentication</InputLabel>
                        <Select
                          id="endpointAuthentication"
                          label="Endpoint Authentication"
                          value={apiEndpointsFormData.endpointAuthentication}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              endpointAuthentication: e.target.value,
                            });
                          }}
                        >
                          {Array.from(apiAuthSet).map((authData: any, index: number) => {
                            return (
                              <MenuItem value={authData} key={index}>
                                {authData}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel htmlFor="textField">Summary</InputLabel>
                        <Input
                          id="textField"
                          type="text"
                          inputProps={{ maxLength: 50 }}
                          value={apiEndpointsFormData.summary}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              summary: e.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="flex flex-col mb-6">
                      <FormControl className="mb-2">
                        <InputLabel htmlFor="textField">Tag</InputLabel>
                        <Input
                          id="textField"
                          type="text"
                          inputProps={{ maxLength: 50 }}
                          value={apiEndpointsFormData.tag}
                          onChange={(e) => {
                            setApiEndpointsFormData({
                              ...apiEndpointsFormData,
                              tag: e.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </fieldset>
              </div>

              <br />
              <br />

              <div>
                <fieldset style={{ border: 'solid 1px #A9B2B6', padding: '20px' }}>
                  <legend style={{ fontSize: '1.125rem', color: 'blue' }}>
                    Request and Responses
                  </legend>

                  <div>
                    <Stepper activeStep={verticalActiveStep} orientation="vertical">
                      {verticalSteps.map((menu, index) => (
                        <Step key={index}>
                          <StepLabel color="inherit" onClick={() => goVerticalStepTo(index)}>
                            {menu}
                          </StepLabel>
                          <StepContent>
                            {verticalActiveStep === 0 && (
                              <div className="flex-auto p-6 sm:p-10">
                                <div className="max-w-xl">
                                  {/* Text Field */}
                                  <div className="flex flex-col mb-6">
                                    <FormControl className="mb-2">
                                      <InputLabel htmlFor="dropdown" required>
                                        Parameter Type
                                      </InputLabel>
                                      <Select
                                        id="parameterType"
                                        label="Parameter Type"
                                        required
                                        value={apiRequestFormData.parameterType}
                                        onChange={(e) => {
                                          setApiRequestFormData({
                                            ...apiRequestFormData,
                                            parameterType: e.target.value,
                                          });
                                          displayDefineParameterOnParameterTypeChange(
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <MenuItem value="HTTP Header">HTTP Header</MenuItem>
                                        <MenuItem value="Query Parameters">Query</MenuItem>
                                        <MenuItem value="URI Path Segment">Path</MenuItem>
                                        <MenuItem value="requestBody">Request Body</MenuItem>
                                        <MenuItem value="Cookie Items">Cookie</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="flex flex-col mb-6">
                                    <FormControl className="mb-2">
                                      <InputLabel htmlFor="dropdown" required>
                                        Defined Parameter
                                      </InputLabel>
                                      <Select
                                        id="parameterName"
                                        label="Defined Parameter"
                                        required
                                        value={apiRequestFormData.parameterName}
                                        onChange={(e) => {
                                          setApiRequestFormData({
                                            ...apiRequestFormData,
                                            parameterName: e.target.value,
                                          });
                                        }}
                                      >
                                        {parameterNames.map((paramData: any, index: number) => {
                                          return (
                                            <MenuItem value={paramData.value} key={index}>
                                              {paramData.label}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <Link
                                    to="/apifactory/ApiRequestParameters"
                                    style={{ fontSize: '0.875rem', color: 'blue' }}
                                  >
                                    Create Request Parameter
                                  </Link>
                                  <br />
                                  <br />
                                  <div>
                                    <Button onClick={handleVerticalNext}>Next</Button>
                                    <Button variant="contained" onClick={saveRequestParameter}>
                                      Add
                                    </Button>
                                  </div>
                                </div>
                                {requestRows.length > 0 && (
                                  <div>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Parameter Type</TableCell>
                                          <TableCell>Defined Parameter</TableCell>
                                          <TableCell>Action</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(requestRows || []).map((row: any, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell>{row.parameterType}</TableCell>
                                            <TableCell>{row.parameterName}</TableCell>
                                            <TableCell>
                                              <IconButton onClick={() => editRequestRows(row)}>
                                                <EditIcon />
                                              </IconButton>
                                              <IconButton onClick={() => deleteRequestRows(row)}>
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </div>
                                )}
                              </div>
                            )}
                            {verticalActiveStep === 1 && (
                              <div className="flex-auto p-6 sm:p-10">
                                <div className="max-w-xl">
                                  {/* Text Field */}
                                  <div className="flex flex-col mb-6">
                                    <FormControl className="mb-2">
                                      <InputLabel htmlFor="textField" required>
                                        Status Code
                                      </InputLabel>
                                      <Input
                                        id="textField"
                                        type="text"
                                        inputProps={{ maxLength: 5 }}
                                        required
                                        value={apiResponseFormData.responseStatusCode}
                                        onChange={(e) => {
                                          setApiResponseFormData({
                                            ...apiResponseFormData,
                                            responseStatusCode: e.target.value,
                                          });
                                        }}
                                      />
                                    </FormControl>
                                    {!isNumeric(apiResponseFormData.responseStatusCode) && (
                                      <span style={{ color: 'red' }}>
                                        Please enter only numeric values.
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex flex-col mb-6">
                                    <FormControl className="mb-2">
                                      <InputLabel htmlFor="textField" required>
                                        Description
                                      </InputLabel>
                                      <Input
                                        id="textField"
                                        type="text"
                                        inputProps={{ maxLength: 50 }}
                                        required
                                        value={apiResponseFormData.responseDescription}
                                        onChange={(e) => {
                                          setApiResponseFormData({
                                            ...apiResponseFormData,
                                            responseDescription: e.target.value,
                                          });
                                        }}
                                      />
                                    </FormControl>
                                  </div>

                                  <div className="flex flex-col mb-6">
                                    <FormControl className="mb-2">
                                      <InputLabel htmlFor="dropdown" required>
                                        Output Format
                                      </InputLabel>
                                      <Select
                                        id="outputFormat"
                                        label="Output Format"
                                        required
                                        value={apiResponseFormData.outputFormat}
                                        onChange={(e) => {
                                          setApiResponseFormData({
                                            ...apiResponseFormData,
                                            outputFormat: e.target.value,
                                          });
                                        }}
                                      >
                                        <MenuItem value="application/json">
                                          application/json
                                        </MenuItem>
                                        <MenuItem value="application/xml">application/xml</MenuItem>
                                        <MenuItem value="image/jpeg">image/jpeg</MenuItem>
                                        <MenuItem value="image/png">image/png</MenuItem>
                                        <MenuItem value="multipart/form-data">
                                          multipart/form-data
                                        </MenuItem>
                                        <MenuItem value="application/x-www-form-urlencoded">
                                          application/x-www-form-urlencoded
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>

                                  <div className="flex flex-col mb-6">
                                    <FormControl className="mb-2">
                                      <InputLabel htmlFor="dropdown" required>
                                        Response Data Type
                                      </InputLabel>
                                      <Select
                                        id="outputType"
                                        label="Response Data Type"
                                        required
                                        value={apiResponseFormData.outputType}
                                        onChange={(e) => {
                                          setApiResponseFormData({
                                            ...apiResponseFormData,
                                            outputType: e.target.value,
                                          });
                                          displayDataModelOnDataTypeChange(e.target.value);
                                        }}
                                      >
                                        <MenuItem value="string">String</MenuItem>
                                        <MenuItem value="number">Number</MenuItem>
                                        <MenuItem value="Integer">Integer</MenuItem>
                                        <MenuItem value="Object">Object</MenuItem>
                                        <MenuItem value="Boolean">Boolean</MenuItem>
                                        <MenuItem value="Int 32">Int 32</MenuItem>
                                        <MenuItem value="Int 64">Int 64</MenuItem>
                                        <MenuItem value="Float">Float</MenuItem>
                                        <MenuItem value="Double">Double</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  {displayDataModel && (
                                    <div className="flex flex-col mb-6">
                                      <FormControl className="mb-2">
                                        <InputLabel htmlFor="dropdown">
                                          Response Data Model
                                        </InputLabel>
                                        <Select
                                          id="outputDataModelId"
                                          label="Response Data Model"
                                          value={apiResponseFormData.outputDataModelId}
                                          onChange={(e) => {
                                            setApiResponseFormData({
                                              ...apiResponseFormData,
                                              outputDataModelId: e.target.value,
                                            });
                                          }}
                                        >
                                          {dataModels.map((dataModel: any, index: number) => {
                                            return (
                                              <MenuItem value={dataModel.DataModelID} key={index}>
                                                {dataModel.DataModelName}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  )}
                                  <div className="flex flex-col">
                                    <label style={{ fontSize: '0.875rem' }}>
                                      <input
                                        type="checkbox"
                                        checked={apiResponseFormData.responseInArray}
                                        onChange={handleResponseArrayCheckboxChange}
                                      />
                                      Response in Array
                                    </label>
                                  </div>
                                  <br />
                                  <div>
                                    <Button variant="contained" onClick={saveResponseParameters}>
                                      Add
                                    </Button>
                                  </div>
                                </div>
                                {responseRow.length > 0 && (
                                  <div>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Status Code</TableCell>
                                          <TableCell>Description</TableCell>
                                          <TableCell>Output</TableCell>
                                          <TableCell>Output Type</TableCell>
                                          <TableCell>Action</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(responseRow || []).map((row: any, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell>{row.responseStatusCode}</TableCell>
                                            <TableCell>{row.responseDescription}</TableCell>
                                            <TableCell>{row.outputFormat}</TableCell>
                                            <TableCell>{row.outputType}</TableCell>
                                            <TableCell>
                                              <IconButton onClick={() => editResponseRows(row)}>
                                                <EditIcon />
                                              </IconButton>
                                              <IconButton onClick={() => deleteResponseRows(row)}>
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </div>
                                )}
                              </div>
                            )}
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </fieldset>
              </div>
              <br />
              <section className="flex justify-between items-center">
                <Button variant="contained" type="button" onClick={saveEndPoint}>
                  Save Endpoint
                </Button>
                <Button onClick={() => handleBack()} variant="contained" type="button">
                  Back
                </Button>
              </section>

              {endpointRow.length > 0 && (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Endpoint Name / Operation ID</TableCell>
                        <TableCell>Path</TableCell>
                        <TableCell>Operation Type</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Summary</TableCell>
                        <TableCell>Tag</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(endpointRow || []).map((row: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{row.endpointName}</TableCell>
                          <TableCell>{row.path}</TableCell>
                          <TableCell>{row.operation}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.summary}</TableCell>
                          <TableCell>{row.tag}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => editEndpoint(row)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteEndpoint(row)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          )}
          {activeStep === 2 && (
            <div>
              <div className="flex justify-end">
                <Button
                  className="px-8"
                  mat-flat-button
                  type="button"
                  variant="contained"
                  onClick={pushSpecificationToRepo}
                >
                  Push To Repository
                </Button>
              </div>
              <br />
              <div>
                <div id="swagger-editor"></div>
              </div>
            </div>
          )}
        </form>
      </main>
      <DialogBox isOpen={isDialogOpen} handleClose={handleCloseDialog} content={dialogContent} />
    </div>
  );
};

export default DesignApiEndpoints;
