import { SetStateAction, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Radio,
  DialogContent,
  FormLabel,
  Typography,
  RadioGroup,
} from '@mui/material';
import ApiService from 'app/services/fileupload.service';
import useDialog from '../hooks/popup';
import { useMsal } from '@azure/msal-react';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/layouts/store/slices/dialog.slice';

const AddProjectAdmin = ({ onCloseDialog }: { onCloseDialog: () => void }) => {
  const { instance } = useMsal();
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [repositoryName, setRepositoryName] = useState('');
  const [userName, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [accountName, setAccountName] = useState('');
  const [repository, setSelectedRepository] = useState('');

  const [error, setError] = useState<string | null>(null);
  const { showDialog } = useDialog();
  const [storage, setSelectedradioValue] = useState('local');
  const [accountNames, setAccountNames] = useState([]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!projectName) {
      newErrors.projectName = 'Project Name is required';
    }

    if (!description) {
      newErrors.description = 'Description is required';
    }

    if (!accountName) {
      newErrors.accountName = 'Account Name is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleStorageChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSelectedradioValue(event.target.value);
  };

  const dispatch = useDispatch();
  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (validateForm()) {
      const form = {
        projectName,
        description,
        accountName,
        storage,
        repositoryName,
        repository,
        userName,
        token,
      };

      ApiService.saveProjectDetails(instance, form)
        .then((response) => {
          const responseData = JSON.parse(response.data);
          if (responseData.code >= 200 && responseData.code < 210) {
            console.log('response', responseData.data);

            showDialog('Project Details Added successfully ');
            dispatch(showSuccessMessage('Project Details Added successfully '));
            dispatch(closeDialog());
            onCloseDialog();
          } else {
            // Non-2xx status code
            setError(`Request failed with status code ${responseData.code}`);
            showDialog(
              'Something went wrong , Please try after sometime',
              <p>{responseData.message}</p>
            );
            dispatch(showErrorMessage('Something went wrong ,please try after sometime.'));
          }
        })
        .catch((errorInstance) => {
          console.error('Error:', errorInstance);

          let errorMessage: string;
          if (errorInstance.message.includes('Network Error')) {
            errorMessage = 'Something went wrong , Please try after sometime.';
          } else {
            errorMessage = `Request failed: ${errorInstance.responseData.message}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
          dispatch(showErrorMessage(<p>{errorMessage}</p>));
        });
    }
  };

  const getAccountNameinDialog = () => {
    ApiService.getAccountName(instance)
      .then((response) => {
        console.log('response AccountName', response?.data?.data);
        setAccountNames(response?.data?.data);
      })
      .catch((errorInstance: any) => {
        console.error('Error:', errorInstance);
      });
  };

  useEffect(() => {
    getAccountNameinDialog();
  }, []);

  const handleAccountNameChange = (e: { target: { value: SetStateAction<string> } }) => {
    setAccountName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, accountName: '' }));
  };
  const handleProjectNameChange = (e: { target: { value: SetStateAction<string> } }) => {
    setProjectName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, projectName: '' }));
  };

  const handleDescriptionChange = (e: { target: { value: SetStateAction<string> } }) => {
    setDescription(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, description: '' }));
  };

  return (
    <DialogContent>
      <form onSubmit={handleFormSubmit}>
        <Typography variant="h5">Add New Project Details</Typography>
        <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <Button
            type="submit"
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            style={{ marginLeft: '420px' }}
          >
            Create Project
          </Button>
        </div>

        <TextField
          label="Project Name"
          value={projectName}
          inputProps={{ maxLength: 20 }}
          onChange={handleProjectNameChange}
          fullWidth
          required
          margin="normal"
          helperText={
            errors.projectName && (
              <Typography variant="caption" color="error">
                {errors.projectName}
              </Typography>
            )
          }
        />
        <TextField
          style={{ width: '100%', marginBottom: '20px' }}
          label="Description"
          inputProps={{ maxLength: 40 }}
          value={description}
          onChange={handleDescriptionChange}
          fullWidth
          margin="normal"
          required
          helperText={
            errors.description && (
              <Typography variant="caption" color="error">
                {errors.description}
              </Typography>
            )
          }
        />
        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Account Name </InputLabel>
          <Select
            value={accountName}
            label="Account Name"
            onChange={handleAccountNameChange}
            // error={Boolean(errors.accountName)}
            // displayEmpty
          >
            {accountNames.map((account: { id: number; name: string }) => (
              <MenuItem key={account.id} value={account.name}>
                {account.name}
              </MenuItem>
            ))}
          </Select>
          {errors.accountName && (
            <Typography variant="caption" color="error">
              {errors.accountName}
            </Typography>
          )}
        </FormControl>

        <FormControl component="fieldset" style={{ width: '100%', marginBottom: '20px' }}>
          <FormLabel required component="legend">
            Storage
          </FormLabel>
          <RadioGroup
            style={{ flexDirection: 'row' }}
            value={storage}
            onChange={handleStorageChange}
          >
            <FormControlLabel
              value="local"
              control={<Radio />}
              label="Local"
              style={{ marginRight: '20px' }}
            />
            <FormControlLabel value="repository" control={<Radio />} label="Repository" />
          </RadioGroup>
        </FormControl>

        <TextField
          style={{ width: '100%', marginBottom: '20px' }}
          label="Repository Name"
          inputProps={{ maxLength: 20 }}
          value={repositoryName}
          onChange={(e) => setRepositoryName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl style={{ width: '100%', marginBottom: '10px' }}>
          <InputLabel>Select Repository</InputLabel>
          <Select
            value={repository}
            label="Select Repository"
            onChange={(e) => setSelectedRepository(e.target.value)}
          >
            <MenuItem value="GIT">GIT</MenuItem>
            <MenuItem value="SVN">SVN</MenuItem>
            <MenuItem value="BitBucket">BitBucket</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Username"
          value={userName}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Token"
          type="password"
          inputProps={{ maxLength: 150 }}
          value={token}
          onChange={(e) => setToken(e.target.value)}
          fullWidth
          margin="normal"
        />
      </form>{' '}
    </DialogContent>
  );
};

export default AddProjectAdmin;
