import { createSlice } from '@reduxjs/toolkit';
import { IUser, IUserRequest } from 'app/models';
import {
  fetchLocationsByTenant,
  fetchRolesByScope,
  fetchRolesByTenant,
  fetchUserByRole,
  fetchProjectDataByTenant,
  fetchProjData,
  fetchRequestData,
  fetchCurrentRequestData,
} from '../actions/users.actions';

type InitialState = {
  users: IUser[];
  loading: boolean;
  locationsByTenant: [];
  rolesByTenant: [];
  projects: [];
  projectRoles: [];
  userrequest: IUserRequest[];
  currentUserRequest: any;
  rolesByProject: [];
  editRequestData: any;
};

const initialState: InitialState = {
  users: [],
  loading: false,
  locationsByTenant: [],
  rolesByTenant: [],
  projects: [],
  projectRoles: [],
  userrequest: [],
  currentUserRequest: {},
  rolesByProject: [],
  editRequestData: [],
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserByRole.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(fetchLocationsByTenant.fulfilled, (state, action) => {
        state.locationsByTenant = action.payload;
      })
      .addCase(fetchRolesByTenant.fulfilled, (state, action) => {
        state.rolesByTenant = action.payload;
      })
      .addCase(fetchProjectDataByTenant.fulfilled, (state, action) => {
        state.projects = action.payload.projects;
        state.projectRoles = action.payload.projRoles;
      })
      .addCase(fetchRequestData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRequestData.fulfilled, (state, action) => {
        state.userrequest = action.payload;
      })
      .addCase(fetchCurrentRequestData.fulfilled, (state, action) => {
        state.currentUserRequest = action.payload;
      })

      .addCase(fetchRolesByScope.fulfilled, (state, action) => {
        state.rolesByProject = action.payload;
      })
      .addCase(fetchProjData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjData.fulfilled, (state, action) => {
        state.editRequestData = action.payload;
        state.loading = false;
      });
  },
});

export default usersSlice.reducer;
