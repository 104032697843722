import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { AppBar, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'app/store';
import { toggleColorMode } from '../store/slices/theme.slice';
import UserMenu from '../components/UserMenu';
import { Link } from 'react-router-dom';
import Logo from 'assets/logo.svg';

function Header() {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <AppBar id="layout__header" color="inherit">
      <Toolbar className="flex justify-between" disableGutters>
        <section className="flex items-center">
          <div className="w-[4rem] grid place-items-center">
            <img src={Logo} alt="logo" />
          </div>
          <Divider orientation="vertical" flexItem />
          <Typography
            component={Link}
            to="/"
            className="hidden md:block leading-tight font-semibold tracking-wide ml-4 text-xl"
          >
            RapidX App Designer
          </Typography>
        </section>
        <div className="flex items-center h-full">
          <IconButton onClick={() => dispatch(toggleColorMode())}>
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
