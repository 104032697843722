import { RequestHandler } from 'libs/request-handler';
import { Services } from 'app/models';
import { IPublicClientApplication } from '@azure/msal-browser';

const apiServiceName: Services = 'mra-management';

class StackOfTechService extends RequestHandler {
  endpath = 'stackoftech';

  getAllStackOfTechs(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}`);
  }

  getAllTechnologies(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/technologies`);
  }
}

export default new StackOfTechService(apiServiceName);
