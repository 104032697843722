import { createSlice } from '@reduxjs/toolkit';
import { Field, IEntity } from 'app/models';
import {
  fetchComponentEntities,
  addEntity,
  updateEntity,
  addEntitywithNestedRelations,
} from '../actions/entity.actions';

type InitialEntityState = {
  entity: IEntity[];
  loading: boolean;
  tabValue: string;
  businessEntity: IEntity[];
  contractEntity: IEntity[];
  selectedEntity: any;
  status: string;
  modelEntities: any;
};

const initialState: InitialEntityState = {
  entity: [],
  loading: false,
  tabValue: 'business',
  businessEntity: [],
  contractEntity: [],
  selectedEntity: {},
  status: '',
  modelEntities: [],
};

const entitySlice = createSlice({
  name: 'entitySlice',
  initialState,
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
      if (action.payload === 'business') {
        state.businessEntity = state.entity.filter((item) => item.entityType === action.payload);
      } else
        state.contractEntity = state.entity.filter((item) => item.entityType === action.payload);
    },
    setSelectedEntity: (state, action) => {
      state.selectedEntity = action.payload;
    },
    addFieldValidations: (state, action) => {
      const { index, validations } = action.payload;
      const fieldindex = state.selectedEntity?.fields.findIndex(
        (field: Field) => field.id === index
      );
      state.selectedEntity.fields[fieldindex].validations = validations;
    },
    setModelEntities: (state, action) => {
      state.modelEntities = action.payload;
    },
    setContracts: (state, action) => {
      state.contractEntity = action.payload;
    },
    setBusiness: (state, action) => {
      state.businessEntity = action.payload;
    },
    setDeleteEntity: (state, action) => {
      state.entity = state.entity.filter((item) => item._id !== action.payload);
      state.selectedEntity = {};
      if (state.tabValue === 'business') {
        state.businessEntity = state.entity.filter((item) => item.entityType === state.tabValue);
        state.selectedEntity = state.businessEntity.length > 0 ? state.businessEntity[0] : {};
      } else {
        state.contractEntity = state.entity.filter((item) => item.entityType === state.tabValue);
        state.selectedEntity = state.contractEntity.length > 0 ? state.contractEntity[0] : {};
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComponentEntities.fulfilled, (state, action) => {
        state.entity = action.payload;
        state.businessEntity = action.payload.filter(
          (item: IEntity) => item.entityType === 'business'
        );
        state.contractEntity = action.payload.filter(
          (item: IEntity) => item.entityType === 'contracts'
        );
        state.loading = false;
      })
      .addCase(fetchComponentEntities.pending, (state) => {
        state.loading = true;
      })
      .addCase(addEntity.fulfilled, (state) => {
        state.status = 'success';
        state.loading = false;
      })
      .addCase(addEntity.pending, (state) => {
        state.loading = true;
      })
      .addCase(addEntity.rejected, (state) => {
        state.status = 'failure';
        state.loading = false;
      })
      .addCase(updateEntity.fulfilled, (state) => {
        state.loading = false;
        state.status = 'success';
      })
      .addCase(updateEntity.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEntity.rejected, (state) => {
        state.loading = false;
        state.status = 'failure';
      })
      .addCase(addEntitywithNestedRelations.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addEntitywithNestedRelations.pending, (state) => {
        state.loading = true;
      })
      .addCase(addEntitywithNestedRelations.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setTabValue,
  setContracts,
  setBusiness,
  setSelectedEntity,
  setModelEntities,
  addFieldValidations,
  setDeleteEntity,
} = entitySlice.actions;

export default entitySlice.reducer;
