export class oAuth {
  authenticationName: string;
  flowType: string;
  authorizationURL: string;
  refreshURL: string;
  tokenURL: string;
  constructor(
    authenticationName: string,
    flowType: string,
    authorizationURL: string,
    refreshURL: string,
    tokenURL: string
  ) {
    this.authenticationName = authenticationName;
    this.flowType = flowType;
    this.authorizationURL = authorizationURL;
    this.refreshURL = refreshURL;
    this.tokenURL = tokenURL;
  }
}
