import { IPublicClientApplication } from '@azure/msal-browser';
import { IComponent, IComponentStatus, IComponentStatusByAppId, Services } from 'app/models';
import { AxiosRequestConfig } from 'axios';
/*eslint import/no-cycle: [2, { maxDepth: 2 }]*/
import { RequestHandler } from 'libs/request-handler';
import { CreateComponentDTO } from 'app/dtos';

const apiServiceName: Services = 'app-designer';
class ComponentService extends RequestHandler {
  endpath = 'component';

  getAllComponents(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IComponent[]>(
      `/${this.endpath}/getApplicationComponents/${appId}`,
      config
    );
  }

  getComponent(instance: IPublicClientApplication, componentId: string, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IComponent>(
      `/${this.endpath}/get/${componentId}`,
      config
    );
  }
  getComponentEntities(instance: IPublicClientApplication, componentId: string, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(
      `/${this.endpath}/getComponentEntities/${componentId}`,
      config
    );
  }

  generateCrud(
    instance: IPublicClientApplication,
    data: { componentId?: string; id: string },
    applicationId: string
  ) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(`/${this.endpath}/addEntity`, data, config);
  }

  retryComponent(instance: IPublicClientApplication, componentId: string, applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `/${this.endpath}/create/retry/${componentId}`,
      config
    );
  }

  getApplicationComponents(instance: IPublicClientApplication, applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IComponent[]>(
      `/${this.endpath}/getApplicationComponents/${applicationId}`,
      config
    );
  }

  createComponent(instance: IPublicClientApplication, component: CreateComponentDTO) {
    const headers = { applicationid: component.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IComponent>(
      `/${this.endpath}/create`,
      component,
      config
    );
  }

  deleteComponent(instance: IPublicClientApplication, componentId: string, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IComponent>(
      `${this.endpath}/delete`,
      {
        id: componentId,
      },
      config
    );
  }

  generateBusinessValidation(
    instance: IPublicClientApplication,
    data: { componentId: string; id: string },
    applicationId: string
  ) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `/${this.endpath}/addBusinessValidation`,
      data,
      config
    );
  }

  createZip(instance: IPublicClientApplication, appId: string, componentId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<{ message: string }>(
      `/${this.endpath}/createZip/${appId}/${componentId}`,
      config
    );
  }

  downloadCode(instance: IPublicClientApplication, appId: string, componentId: string) {
    const headers = { applicationid: appId };
    return this.requestHandler(instance).get<Blob>(
      `/${this.endpath}/downloadCode/${appId}/${componentId}`,
      { responseType: 'blob', headers }
    );
  }

  getComponentStatusByAppId(instance: IPublicClientApplication, applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IComponentStatusByAppId>(
      `/${this.endpath}/status/app/${applicationId}`,
      config
    );
  }
}
export default new ComponentService(apiServiceName);
