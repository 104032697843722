import { IPublicClientApplication } from '@azure/msal-browser';
import {
  AzureAppServiceDTO,
  ChangeContainerRegistryDTO,
  CreateEnvironmentDTO,
  DeploymentDTO,
  ValidateContainerRegistryDTO,
} from 'app/dtos';
import { ContainerRegistry, Credentials, IEnvironment } from 'app/models';
import { IApplicationPipelineConfig } from 'app/models/pipeline/application-pipeline-config.model';
import { AxiosRequestConfig } from 'axios';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName = 'pipeline-service';

class ApplicationPipelineConfigService extends RequestHandler {
  endpath = 'applicationPipelineConfig';

  getWebAppsList(instance: IPublicClientApplication, appid: string) {
    const headers = { applicationid: appid };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<AzureAppServiceDTO[]>(
      `/${this.endpath}/getWebApps/${appid}`,
      config
    );
  }

  getPipelineConfig(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IApplicationPipelineConfig>(
      `/${this.endpath}/get/${appId}`,
      config
    );
  }

  updateDeployment(instance: IPublicClientApplication, details: DeploymentDTO) {
    const headers = { applicationid: details.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(`${this.endpath}/addDeployment`, details, config);
  }

  changeContainerRegistryDetails(
    instance: IPublicClientApplication,
    details: ChangeContainerRegistryDTO
  ) {
    const headers = { applicationid: details.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<ContainerRegistry>(
      `${this.endpath}/changeContainerRegistry`,
      details,
      config
    );
  }

  validateContainerRegistry(instance: IPublicClientApplication, data: Credentials, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<ValidateContainerRegistryDTO>(
      `${this.endpath}/validate-container-registry`,
      data,
      config
    );
  }

  createEnvs(instance: IPublicClientApplication, appid: string, data: CreateEnvironmentDTO) {
    const headers = { applicationid: appid };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IEnvironment>(
      `/${this.endpath}/createEnv/${appid}`,
      data,
      config
    );
  }
}

export default new ApplicationPipelineConfigService(apiServiceName);
