/*eslint import/no-cycle: [2, { maxDepth: 2 }]*/
import { RequestHandler } from 'libs/request-handler';
import { IPublicClientApplication } from '@azure/msal-browser';
import { IChanges, ICreatePrStatusResponse, IGetCreatePrResponse, Services } from 'app/models';
import { OctokitOrganizations } from 'app/dtos';
import { AxiosRequestConfig } from 'axios';

const apiServiceName: Services = 'app-designer';

class GithubService extends RequestHandler {
  endpath = 'github';

  createPullRequest(
    instance: IPublicClientApplication,
    applicationId: string,
    commitMessage: string
  ) {
    const data = {
      message: commitMessage,
      appId: applicationId,
    };
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IGetCreatePrResponse>(
      `/${this.endpath}/create/pullrequest`,
      data,
      config
    );
  }

  // getPRstatus(instance: IPublicClientApplication, instanceId: string) {
  //   const data = {
  //     instanceId: instanceId,
  //   };
  //   const headers = { instanceid: instanceId };
  //   const config: AxiosRequestConfig = { headers };
  //   return this.requestHandler(instance).get<ICreatePrStatusResponse>(
  //     `/${this.endpath}/pr/status/${data.instanceId}`,
  //     config
  //   );
  // }

  getRepoDetails(instance: IPublicClientApplication, appId: string, owner: string, repo: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(
      `/${this.endpath}/repo?appId=${appId}&owner=${owner}&repo=${repo}`,
      config
    );
  }

  listOrganizations(instance: IPublicClientApplication, token: string) {
    const data = {
      token,
    };
    return this.requestHandler(instance).post<OctokitOrganizations>(
      `${this.endpath}/organizations/list`,
      data
    );
  }
}

export default new GithubService(apiServiceName);
