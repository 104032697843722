import Lottie from 'lottie-react';
import animation from '../../assets/404.json';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <main className="flex flex-col lg:flex-row justify-center items-center w-[100vw] h-[100vh] gap-10">
      <div className="w-1/3">
        <Lottie animationData={animation} loop={true} />
      </div>
      <section className="flex flex-col gap-2 justify-center items-center p-10 text-center">
        <Typography className="font-bold text-6xl">Oops!</Typography>
        <Typography variant="h4" fontWeight={600}>
          The requested page cannot be found.
        </Typography>
        <Typography color="text.secondary" variant="h6">
          Sorry about that! Please visit our hompage to get where you need to go.
        </Typography>
        <div>
          <Button variant="contained" component={Link} to="/">
            Back to Home!
          </Button>
        </div>
      </section>
    </main>
  );
}

export default NotFound;
