import { IPublicClientApplication } from '@azure/msal-browser';
import { ITenant, Services } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'management';
class TenantManagementService extends RequestHandler {
  endpath = 'tenant';

  getTenant(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get(`/${this.endpath}/${tenantId}`);
  }

  getAllTenants(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}`);
  }

  getTenantByDomain(instance: IPublicClientApplication, domainName: string) {
    return this.requestHandler(instance).get<ITenant>(
      `${this.endpath}/getTenantByDomain/${domainName}`
    );
  }
  getTenantDetailsByDomain(instance: IPublicClientApplication, domainName: string) {
    return this.requestHandler(instance).get<ITenant>(
      `${this.endpath}/getTenantDetailsByDomain/${domainName}`
    );
  }

  checkDomain(domainName: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<{ message: string }>(
      `/${this.endpath}/checkDomain/${domainName}`
    );
  }

  fetchDomain(domainName: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/fetchDomain/${domainName}`);
  }

  getIdp(domainName: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<{ idp: string }>(
      `/${this.endpath}/getIdp/${domainName}`
    );
  }

  getMyTenant(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<ITenant>(`/${this.endpath}/details/getMyTenant`);
  }
  getMyTenantDetails(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<ITenant>(
      `/${this.endpath}/details/getMyTenantDetails`
    );
  }
}

export default new TenantManagementService(apiServiceName);
