import { createAsyncThunk } from '@reduxjs/toolkit';
import userService from 'app/services/iam/user.service';
import roleService from 'app/services/iam/role.service';
import userRequestService from 'app/services/iam/user-request.service';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import mraService from 'app/services/mra-management/mra.service';
import locationService from 'app/services/iam/location.service';
import tenantManagementService from 'app/services/management/tenant-management.service';
import projectService from 'app/services/management/project.service';

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const fetchUser = createAsyncThunk('dashboard/fetchUser', async (data: ActionPayload) => {
  const response = await userService.validateUser(data.instance);
  return response.data;
});

export const fetchUserByRole = createAsyncThunk(
  'dashboard/fetchUserByRole',
  async (data: ActionPayload<{ tenantId: string }>) => {
    const response = await userService.findUserByRole(data.instance, data.tenantId);
    return response.data;
  }
);

export const fetchLocationsByTenant = createAsyncThunk(
  'dashboard/fetchLocationsByTenant',
  async (data: ActionPayload<{ tenantId: string }>) => {
    const response = await userService.fetchLocationsByTenant(data.instance, data.tenantId);
    return response.data;
  }
);

export const fetchRolesByTenant = createAsyncThunk(
  'dashboard/fetchRolesByTenant',
  async (data: ActionPayload<{ tenantId: string }>) => {
    const response = await roleService.fetchRolesByTenant(data.instance, data.tenantId);
    return response.data;
  }
);

export const fetchRequestData = createAsyncThunk(
  'dashboard/fetchRequestData',
  async (data: ActionPayload<{ tenantId: string }>) => {
    const response = await userRequestService.getRequestByTenant(data.tenantId, data.instance);
    return response.data;
  }
);
export const fetchCurrentRequestData = createAsyncThunk(
  'dashboard/fetchCurrentRequestData',
  async (data: ActionPayload<{ requestId: any }>) => {
    const response = await userRequestService.getRequestById(data.requestId, data.instance);
    return response.data;
  }
);
export const fetchProjectDataByTenant = createAsyncThunk(
  'dashboard/fetchProjectDataByTenant',
  async (data: ActionPayload<{ tenantId: string }>) => {
    const projects = await projectService.fetchProjectsByTenant(data.tenantId, data.instance);
    const projRoles = await roleService.fetchRolesByScope(data.instance, 'Project');
    return {
      projects: projects.data,
      projRoles: projRoles.data,
    };
  }
);

export const rejectRequestAccess = createAsyncThunk(
  'dashboard/rejectRequestAccess',
  async (data: ActionPayload<{ requestId: string; userRequest: any }>, thunkAPI) => {
    try {
      // console.log('Request : ', data.requestId);
      return await userRequestService
        .rejectRequest(data.requestId, data.instance, data.userRequest)
        .then((res) => {
          // console.log('Request : ', res);
          if (res?.status === 200) {
            thunkAPI.dispatch(showSuccessMessage('edit is successful'));
          } else {
            thunkAPI.dispatch(showErrorMessage('edit is unsuccessful'));
          }
          return res;
        });
    } catch (err) {
      return err;
    }
  }
);
export const approveRequestAccess = createAsyncThunk(
  'dashboard/approveRequestAccess',
  async (data: ActionPayload<{ requestId: string; userRequest: any }>, thunkAPI) => {
    try {
      // console.log('Request : ', data.requestId);
      return await userRequestService
        .approveRequest(data.requestId, data.instance, data.userRequest)
        .then((res) => {
          // console.log('Request : ', res);
          if (res?.status === 200) {
            thunkAPI.dispatch(showSuccessMessage('User Request approved successfully!'));
          } else {
            thunkAPI.dispatch(showErrorMessage('User Request approval Failed'));
          }
          return res;
        });
    } catch (err) {
      return err;
    }
  }
);
export const updateProjectRoles = createAsyncThunk(
  'dashboard/updateProjectRoles',
  async (data: ActionPayload<{ requestId: string; projData: any }>, thunkAPI) => {
    try {
      // console.log('Request:', data.requestId);
      return await userRequestService
        .updateProjectRoles(data.requestId, data.instance, data.projData)
        .then((res) => {
          // console.log('Request:', res);
          if (res?.status == 200) {
            thunkAPI.dispatch(showSuccessMessage('Project roles added successfully'));
          } else {
            thunkAPI.dispatch(showErrorMessage('unable to add the role,please try again later'));
          }
          return res;
        });
    } catch (err) {
      return err;
    }
  }
);

export const fetchRolesByScope = createAsyncThunk(
  'dashboard/fetchRolesByScope',
  async (data: ActionPayload<{ scope: string }>) => {
    const response = await roleService.fetchRolesByScope(data.instance, data.scope);
    return response.data;
  }
);

export const fetchProjData = createAsyncThunk(
  'dashboard/fetchProjData',
  async (data: ActionPayload<{ requestId: string }>) => {
    const response = await userRequestService.findProjectTableData(data.requestId, data.instance);
    return response.data;
  }
);

export const fetchCurrentUserEffortSaved = createAsyncThunk(
  'userManagementStore/getEffortSavedByUser',
  async (data: ActionPayload<{ userId: string }>) => {
    const fetchEffortSaved = await mraService.getTotalEffortSavedByUser(data.userId, data.instance);
    return fetchEffortSaved;
  }
);

export const fetchCurrentUserMraCount = createAsyncThunk(
  'userManagementStore/getMRACountByUser',
  async (data: ActionPayload<{ userId: string }>) => {
    const fetchCount = await mraService.getMRACountByUser(data.userId, data.instance);

    return fetchCount;
  }
);

export const getLocationsByTenant = createAsyncThunk(
  'dashboard/getLocationsByTenant',
  async (data: ActionPayload<{ tenantId: string }>) => {
    try {
      const locationsDetails: any = await locationService.getLocationsByTenant(
        data.instance,
        data.tenantId
      );
      const finalFullLocationData = [];
      const finalLocationData = [];
      const locationsData = locationsDetails.data;
      for (const key in locationsData) {
        const location: any = {
          ...locationsData[key],
          totalLocation: capitalize(locationsData[key].name) + ', ' + locationsData[key].country,
        };
        finalFullLocationData.push(location);
        finalLocationData.push(
          capitalize(locationsData[key].name) + ', ' + locationsData[key].country
        );
      }
      return {
        locations: finalFullLocationData,
        data: finalLocationData,
        status: 200,
      };
    } catch (err) {
      return {
        locations: [],
        data: ['Empty'],
        status: 404,
      };
    }
  }
);

export const checkTenantByDomain = createAsyncThunk(
  'dashboard/checkTenantByDomain',
  async (data: ActionPayload<{ email: string }>) => {
    try {
      let domain = '';
      if (data.email !== '') domain = data.email.split('@')[1];

      const response = await tenantManagementService.fetchDomain(domain, data.instance);
      if (response) return { result: response.data, status: 200 };
      else return { result: {}, status: 404 };
    } catch (err) {
      return err;
    }
  }
);
