import { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  Typography,
  Select,
  FormControl,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';
import ApiService from 'app/services/fileupload.service';
import { useMsal } from '@azure/msal-react';
import useDialog from '../hooks/popup';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch, useSelector } from 'app/store';
import { fetchUserByRole } from 'app/main/dashboard/store/actions/users.actions';
import { closeDialog } from 'app/layouts/store/slices/dialog.slice';
import Loading from 'app/components/Loading';

function AddUser({ formdata, onCloseDialog }: any) {
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const { instance } = useMsal();
  const [projectId] = useState(formdata.projectId || '');
  const [error, setError] = useState<string | null>(null);
  const { showDialog } = useDialog();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const authenticatedUser = useSelector((state) => state.authSlice.user);
  const { users } = useSelector((state) => state.dashboardSlice.users);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchUserByRole({ tenantId: authenticatedUser.tenant, instance }));
    setLoading(false);
  }, [dispatch, authenticatedUser.tenant, instance]);

  const handleEmailChange = (event: React.ChangeEvent<any>, value: string | string[]) => {
    setSelectedEmails(value as string[]);
    console.log(selectedEmails);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const requestBody = {
      email: selectedEmails,
      projectId: projectId,
    };
    setLoading(true);
    ApiService.saveUserProject(instance, requestBody)
      .then((response) => {
        const responseData = JSON.parse(response.data);
        if (responseData.code >= 200 && responseData.code < 210) {
          console.log('response', responseData.data);

          showDialog('User(s) Added successfully ');
          dispatch(showSuccessMessage('User(s) Added successfully  '));
          dispatch(closeDialog());
          onCloseDialog();
        } else {
          // Non-2xx status code

          setError(`Request failed with status code ${responseData.code}`);
          showDialog(
            'Something went wrong , Please try after sometime',
            <p>{responseData.message}</p>
          );
          dispatch(showErrorMessage(<p>{responseData.message}</p>));
        }
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);

        let errorMessage: string;
        if (errorInstance.message.includes('Network Error')) {
          errorMessage = 'Something went wrong , Please try after sometime.';
        } else {
          errorMessage = `Request failed: ${errorInstance.responseData.message}`;
        }

        setError(errorInstance);
        showDialog('Error', <p>{errorMessage}</p>);
        dispatch(showErrorMessage(<p>{errorMessage}</p>));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DialogContent>
      <form onSubmit={handleFormSubmit}>
        <Typography variant="h5">Add New User(s)</Typography>
        <br></br>
        <Loading loading={loading} />
        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <Autocomplete
            multiple
            id="email-search"
            options={users.map((user: any) => user.email)}
            value={selectedEmails}
            onChange={(event: React.ChangeEvent<any>, newValue: string | string[]) =>
              handleEmailChange(event, newValue)
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Search and Select Email Id" variant="outlined" />
            )}
          />
        </FormControl>

        <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '420px' }}>
            Submit
          </Button>
        </div>
      </form>
    </DialogContent>
  );
}

export default AddUser;
