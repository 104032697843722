import { EndpointRequestParameters } from './endpointRequestParameters.model';
import { EndpointResponseParameters } from './endpointResponseParametres.model';
export class APIEndpoints {
  endpointName: string;
  endpointPath: string;
  endpointOperation: string;
  endpointDescription: string;
  endpointAuthentication: string;
  endpointSummary: string;
  endpointTag: string;
  requestParametersList: EndpointRequestParameters[] = [];
  responseParametersList: EndpointResponseParameters[] = [];
  constructor(
    endpointName: string,
    endpointPath: string,
    endpointOperation: string,
    endpointDescription: string,
    endpointAuthentication: string,
    endpointSummary: string,
    endpointTag: string,
    requestParametersList: EndpointRequestParameters[],
    responseParametersList: EndpointResponseParameters[]
  ) {
    this.endpointName = endpointName;
    this.endpointPath = endpointPath;
    this.endpointOperation = endpointOperation;
    this.endpointDescription = endpointDescription;
    this.endpointAuthentication = endpointAuthentication;
    this.endpointSummary = endpointSummary;
    this.endpointTag = endpointTag;
    this.requestParametersList = requestParametersList;
    this.responseParametersList = responseParametersList;
  }
}
