import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import { PermissionEnum } from 'app/models/common/enums';

const UserRequest = lazy(() => import('./pages/UserRequest'));
const Dashboard = lazy(() => import('./Dashboard'));
const UploadMRA = lazy(() => import('./pages/UploadMRA'));
const Applications = lazy(() => import('./pages/Applications'));
const Profile = lazy(() => import('./pages/Profile'));
const Home = lazy(() => import('./pages/Home'));
const MRAs = lazy(() => import('./pages/MRAs'));
const MRAsList = lazy(() => import('./pages/MRAsList'));
const Projects = lazy(() => import('./pages/Projects'));
const Users = lazy(() => import('./pages/Users'));
const AddProject = lazy(() => import('./components/AddProject'));
const EditRequestAccess = lazy(() => import('./components/EditRequestAccess'));
const ProjUsers = lazy(() => import('./pages/ProjUsers'));
const AddProjUser = lazy(() => import('./components/AddProjUser'));
const CreateApplication = lazy(() => import('../application/pages/CreateApplication'));

const dashboardRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />,
    children: [
      {
        path: '',
        element: <Home />,
        handle: { permissions: [] },
      },
      {
        path: '/createApplication',
        element: <CreateApplication />,
      },
      {
        path: 'apps',
        element: <Applications />,
      },
      {
        path: 'users',
        element: <Users />,
        handle: { permissions: [PermissionEnum.LIST_USER] },
      },
      {
        path: 'projects',
        element: <Projects />,
      },
      {
        path: 'addproject',
        element: <AddProject />,
      },
      {
        path: 'projects/users',
        element: <ProjUsers />,
        handle: { permissions: [PermissionEnum.VIEW_PROJECT_USERS] },
      },
      {
        path: 'projects/adduser',
        element: <AddProjUser />,
        handle: { permissions: [PermissionEnum.ASSIGN_PROJECT_USERS] },
      },
      {
        path: 'mras',
        element: <MRAs />,
        children: [
          {
            path: '',
            element: <MRAsList />,
            handle: { permissions: [PermissionEnum.VIEW_MRA] },
          },
          {
            path: 'add',
            element: <UploadMRA />,
            handle: { permissions: [PermissionEnum.ADD_MRA] },
          },
          {
            path: 'edit/:mraId',
            element: <UploadMRA />,
            handle: { permissions: [PermissionEnum.UPDATE_MRA] },
          },
        ],
      },
      {
        path: 'projects/:projectId/users',
        element: <ProjUsers />,
      },
      {
        path: 'user-requests',
        element: <UserRequest />,
        handle: { permissions: [PermissionEnum.USER_REQUEST] },
      },
      {
        path: 'user-requests/edit/:requestId',
        element: <EditRequestAccess />,
        handle: { permissions: [PermissionEnum.USER_REQUEST] },
      },
      {
        path: 'MyProfile',
        element: <Profile />,
      },
    ],
  },
];

export default dashboardRoutes;
