import { IPublicClientApplication } from '@azure/msal-browser';
import { RequestHandler } from 'libs/request-handler';
import { AxiosRequestConfig } from 'axios';
import { Services } from 'app/models';
import { ICommitLogs } from 'app/models/app-designer/appcommitlogs.model';
const apiServiceName: Services = 'app-designer';

class AppCommitLogs extends RequestHandler {
  endpath = 'appcommitlogs';
  getAppCommitLogs(instance: IPublicClientApplication, appId: string) {
    return this.requestHandler(instance).get<ICommitLogs[]>(`/${this.endpath}/getapplogs/${appId}`);
  }
}
export default new AppCommitLogs(apiServiceName);
