import { IPublicClientApplication } from '@azure/msal-browser';
import { Services } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'iam';

class RoleService extends RequestHandler {
  endpath = 'role';

  getUserRolePermissions(instance: IPublicClientApplication) {
    return this.requestHandler(instance).post<string[]>(`/${this.endpath}/getUserRolePermissions`);
  }
  fetchRolesByTenant(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get(`/${this.endpath}/tenant/${tenantId}`);
  }
  fetchRolesByScope(instance: IPublicClientApplication, scope: string) {
    return this.requestHandler(instance).get(`/${this.endpath}/scope/${scope}`);
  }
}

export default new RoleService(apiServiceName);
