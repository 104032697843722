export class StringDataField {
  stringId: string;
  dataFieldName: string;
  stringFormat: string;
  stringDescription: string;
  MinimumLength: string;
  MaximumLength: string;
  DefaultValue: string;
  ExampleValue: string;
  StringPattern: string;

  constructor(
    stringId: string,
    dataFieldName: string,
    stringFormat: string,
    stringDescription: string,
    MinimumLength: string,
    MaximumLength: string,
    DefaultValue: string,
    ExampleValue: string,
    StringPattern: string
  ) {
    this.stringId = stringId;
    this.dataFieldName = dataFieldName;
    this.stringFormat = stringFormat;
    this.stringDescription = stringDescription;
    this.MinimumLength = MinimumLength;
    this.MaximumLength = MaximumLength;
    this.DefaultValue = DefaultValue;
    this.ExampleValue = ExampleValue;
    this.StringPattern = StringPattern;
  }
}
