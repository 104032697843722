import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'app/store';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import ApiService from 'app/services/fileupload.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, LinearProgress, Toolbar, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { openDialog } from 'app/layouts/store/slices/dialog.slice';
import { PersonAdd, PersonRemove } from '@mui/icons-material';
import AddProjectAdmin from './AddProjectAdmin';
import EditProjectAdmin from './EditProjectAdmin';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { AxiosError } from 'axios';
import useConfirmDialog from '../hooks/useConfirmDialog';
import RemoveUser from './RemoveUser';
import { useMsal } from '@azure/msal-react';
import { getAdminNavigation } from 'app/configs/navigation.config';
import AddUser from './AddUser';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import Loading from 'app/components/Loading';

const ProjectAdministration = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'Administration - Project ',
      url: `/apifactory/project`,
    },
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const showConfirmDialog = useConfirmDialog();

  function openAddProjectDialog() {
    dispatch(
      openDialog({
        open: true,
        children: <AddProjectAdmin onCloseDialog={closeDialog} />,
        title: 'Project Administration - Add Project ',
      })
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: 'Project Name',
      type: 'string',
      minWidth: 250,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 200,
      type: 'string',
    },
    {
      field: 'accountName',
      headerName: 'Account Name',
      minWidth: 200,
      type: 'string',
    },
    {
      field: 'storage',
      headerName: 'Storage',
      minWidth: 200,
      type: 'string',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      sortable: false,
      renderCell(params: GridRenderCellParams<any>) {
        return (
          <div className="flex gap-2">
            <Tooltip title="Edit Project">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    openDialog({
                      open: true,
                      children: (
                        <EditProjectAdmin modelData={params.row} onCloseDialog={closeDialog} />
                      ),
                      title: 'Edit Project',
                    })
                  );
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Project">
              <IconButton onClick={() => handleDelete(params.row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Add User">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    openDialog({
                      open: true,
                      children: <AddUser formdata={params.row} onCloseDialog={closeDialog} />,
                      title: 'Add User',
                    })
                  );
                }}
              >
                <PersonAdd />
              </IconButton>
            </Tooltip>

            <Tooltip title="Remove User">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    openDialog({
                      open: true,
                      children: <RemoveUser formdata={params.row} onCloseDialog={closeDialog} />,
                      title: 'Remove User',
                    })
                  );
                }}
              >
                <PersonRemove />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleDelete = useCallback(async (modelData: any) => {
    const confirm = await showConfirmDialog('Are you sure to delete the Project !');
    if (confirm) {
      try {
        const response = await ApiService.deleteProjectDetails(instance, modelData);
        const responseData = JSON.parse(response.data);
        if (responseData.code >= 200 && responseData.code < 210) {
          dispatch(showSuccessMessage('The Project has been deleted.'));
          getProjectinTable();
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          dispatch(showErrorMessage('Unable to delete the Project, please try after sometime.'));
        }
      }
    }
  }, []);

  const getProjectinTable = () => {
    setLoading(true);
    ApiService.getProjectDetails(instance)
      .then((response) => {
        setRecords(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeDialog = () => {
    getProjectinTable();
  };

  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    authorizedNavigation(getAdminNavigation());
    getProjectinTable();
  }, []);

  const getRowId = (record: { projectId: any }) => record.projectId;

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Loading loading={loading} />
      {/* Header */}
      <div className="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div className="flex-1 min-w-0">
          {/* Title */}
          <div className="mt-2">
            <h4 className="text-1xl md:text-2xl font-bold tracking-tight leading-7 sm:leading-10 truncate">
              Project Details
            </h4>
          </div>
        </div>
      </div>

      {/* Main */}
      <main className="flex flex-col gap-2 w-full">
        <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Add Project" arrow>
            <IconButton onClick={openAddProjectDialog} size="large">
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Box sx={{ height: 'auto', width: '100%' }}>
          <DataGrid
            autoHeight
            rows={records}
            columns={columns}
            pageSize={pageSize}
            disableSelectionOnClick
            onPageSizeChange={(newPageSize: SetStateAction<number>) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            getRowId={getRowId}
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </Box>
      </main>
    </div>
  );
};

export default ProjectAdministration;
