import { useState, useEffect } from 'react';
import {
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Badge,
} from '@mui/material';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'app/store';

function SideNavBar() {
  const { navItems } = useSelector((state) => state.layoutSlice.navigation);
  const { mode } = useSelector((state) => state.layoutSlice.theme);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<number>();

  useEffect(() => {
    setActiveItem(navItems.findIndex((item) => matchPath(location.pathname, item.path)));
  }, [location]);

  return (
    <Drawer
      id="layout__navbar"
      variant="persistent"
      open
      anchor="left"
      sx={{
        '& .MuiPaper-root': {
          position: 'fixed',
          top: '4rem',
          left: 0,
          border: 'none',
          boxShadow: 3,
          backgroundColor: mode === 'dark' ? '#272727' : '#ffffff',
        },
      }}
    >
      <List className="flex flex-col gap-2 p-1">
        {navItems.map((menu, id) => (
          <Tooltip title={menu.label} key={menu.id} placement="right" arrow>
            <Link to={menu.path}>
              <ListItem key={menu.id} className="block p-0 rounded-xl" selected={id === activeItem}>
                <ListItemButton className="rounded-xl">
                  <Badge
                    invisible={!menu?.badge}
                    color="primary"
                    badgeContent={!!menu?.badge && menu.badge.content}
                    max={999}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                  </Badge>
                </ListItemButton>
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </List>
    </Drawer>
  );
}

export default SideNavBar;
