import { Button, DialogActions } from '@mui/material';
import { closeDialog, openDialog } from 'app/layouts/store/slices/dialog.slice';
import { useDispatch } from 'app/store';

/*

useConfirmDialog hook provides a confirmation dialog that returns true or false
which can be used in places like "are you sure to perform a certain task" scenarios.

The hook returns a function that can be used to invoke the dialog event.
The returned function returns a promise that can be handled using async await.
The promise is resolved into a boolean

*/

const useConfirmDialog = () => {
  const dispatch = useDispatch();

  function showConfirmDialog(title: string) {
    return new Promise<boolean>((resolve) => {
      dispatch(
        openDialog({
          title,
          closeButton: false,
          children: (
            <>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(closeDialog());
                    resolve(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(closeDialog());
                    resolve(false);
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </>
          ),
        })
      );
    });
  }

  return showConfirmDialog;
};

export default useConfirmDialog;
