import { Dialog as MuiDialog, Typography, IconButton, DialogTitle } from '@mui/material';
import { useSelector, useDispatch } from '../../store';
import { closeDialog } from '../store/slices/dialog.slice';
import CloseIcon from '@mui/icons-material/Close';

function Dialog() {
  const dispatch = useDispatch();
  const { children, open, title, fullScreen, closeButton } = useSelector(
    (state) => state.layoutSlice.dialog
  );
  const close = () => dispatch(closeDialog());

  return (
    <MuiDialog
      open={!!open}
      onClose={(e, reason) => {
        if (reason && reason === 'backdropClick') return;
        close();
      }}
      aria-labelledby="dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle className="flex justify-between items-center">
        <Typography className="font-bold text-lg">{title}</Typography>
        {closeButton && (
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </MuiDialog>
  );
}

export default Dialog;
