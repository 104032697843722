import { CircularProgress, Backdrop } from '@mui/material';

type IProps = {
  loading: boolean;
};

export default function Loading({ loading }: IProps) {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
