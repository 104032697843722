import React from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

interface DialogBoxProps {
  isOpen: boolean;
  handleClose: () => void;
  content: { message: string; link?: any };
}

const DialogBox: React.FC<DialogBoxProps> = ({ isOpen, handleClose, content }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <InfoRounded />
        </IconButton>
        {content.message}
      </DialogTitle>
      <DialogContent>
        {content.link && (
          <a href={content.link} target="_blank" rel="noopener noreferrer">
            {content.link}
          </a>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
