import { useEffect, useState } from 'react';
import { getOnlineNavigation } from 'app/configs/navigation.config';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import ApiService from 'app/services/fileupload.service';
import { FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { Button } from '@mui/material';
import { RequestParameter } from 'app/models/apiRequestModels/requestParameter.model';
import { FieldModel } from 'app/models/apiDataModels/FieldModel.model';
import { showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'app/store';
import { useMsal } from '@azure/msal-react';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import Loading from 'app/components/Loading';

const ApiRequestParameters = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'DesignOnline - Api Request Parameters ',
      url: `/apifactory/ApiRequestParameters`,
    },
  ];
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const initialFormData = {
    parameterName: '',
    parameterType: '',
    description: '',
    dataType: '',
    dataModel: '',
    required: '',
    default: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [displayDataModel, setDisplayDataModel] = useState(false);
  const [dataModels, setDataModels] = useState<any>();

  const [pathParameterArray, setPathParameterArray] = useState<any[]>([]);
  const [queryParameterArray, setQueryParameterArray] = useState<any[]>([]);
  const [headerParameterArray, setHeaderParameterArray] = useState<any[]>([]);
  const [requestBodyArray, setRequestBodyArray] = useState<any[]>([]);
  const [requestParametersList, setRequestParametersList] = useState<RequestParameter[]>([]);

  const [apiRequestFormDataErrors, setApiRequestFormDataErrors] = useState({
    parameterName: '',
    parameterType: '',
    description: '',
    dataType: '',
    dataModel: '',
    required: '',
    default: '',
  });

  const [id, setId] = useState(1);

  const handleParameterNameChange = (event: any) => {
    setFormData({
      ...formData,
      parameterName: event.target.value,
    });
    setApiRequestFormDataErrors({
      ...apiRequestFormDataErrors,
      parameterName: '',
    });
  };
  const handleParameterTypeChange = (event: any) => {
    setFormData({
      ...formData,
      parameterType: event.target.value,
    });
    setApiRequestFormDataErrors({
      ...apiRequestFormDataErrors,
      parameterType: '',
    });
  };
  const handleDescriptionChange = (event: any) => {
    setFormData({
      ...formData,
      description: event.target.value,
    });
    setApiRequestFormDataErrors({
      ...apiRequestFormDataErrors,
      description: '',
    });
  };
  const handleDataTypeChange = (event: any) => {
    setFormData({
      ...formData,
      dataType: event.target.value,
    });
    setApiRequestFormDataErrors({
      ...apiRequestFormDataErrors,
      dataType: '',
    });
  };
  const handleDataModelChange = (event: any) => {
    setFormData({
      ...formData,
      dataModel: event.target.value,
    });
    setApiRequestFormDataErrors({
      ...apiRequestFormDataErrors,
      dataModel: '',
    });
  };
  const handleRequiredChange = (event: any) => {
    setFormData({
      ...formData,
      required: event.target.value,
    });
  };
  const handleDefaultChange = (event: any) => {
    setFormData({
      ...formData,
      default: event.target.value,
    });
  };

  const onChangeDataType = (e: any) => {
    console.log('Data Type Value : ' + e.target.value);
    const dataType = e.target.value;
    if (formData.parameterType === 'requestBody' && dataType === 'object') {
      setDisplayDataModel(true);
    } else {
      setDisplayDataModel(false);
    }
  };

  function onChangeParameterType(e: any) {
    console.log('Parameter Type Value : ' + e.target.value);
    const parameterType = e.target.value;
    if (formData.dataType === 'object' && parameterType === 'requestBody') {
      setDisplayDataModel(true);
    } else {
      setDisplayDataModel(false);
    }
  }

  const saveRequestParameterData = () => {
    console.log('Save Request Parameter Data : ');
    if (validateAPIRequestForm()) {
      // Declare local variables to hold updated lists
      let updatedPathParameterArray = [...pathParameterArray];
      let updatedQueryParameterArray = [...queryParameterArray];
      let updatedHeaderParameterArray = [...headerParameterArray];
      let updatedRequestBodyArray = [...requestBodyArray];
      let updatedRequestParametersList = [...requestParametersList];
      let updatedRequestBody: any | null = null;

      setId(id + 1);

      if (formData.parameterType === 'URI Path Segment') {
        updatedPathParameterArray = [...pathParameterArray, formData.parameterName];
        sessionStorage.setItem('PathParameters', JSON.stringify(updatedPathParameterArray));
        console.log(updatedPathParameterArray);
      }
      if (formData.parameterType === 'Query Parameters') {
        updatedQueryParameterArray = [...queryParameterArray, formData.parameterName];
        sessionStorage.setItem('QueryParameters', JSON.stringify(updatedQueryParameterArray));
        console.log(updatedQueryParameterArray);
      }
      if (formData.parameterType === 'HTTP Header') {
        updatedHeaderParameterArray = [...headerParameterArray, formData.parameterName];
        sessionStorage.setItem('HeaderParameters', JSON.stringify(updatedHeaderParameterArray));
        console.log(updatedHeaderParameterArray);
      }
      if (formData.parameterType === 'requestBody') {
        updatedRequestBodyArray = [...requestBodyArray, formData.parameterName];
        sessionStorage.setItem('RequestBody', JSON.stringify(updatedRequestBodyArray));
        console.log(requestBodyArray);
        const dataModelObj = dataModels.find(
          (d: { DataModelName: string }) => d.DataModelName === formData.dataModel
        );
        updatedRequestBody = new FieldModel('', formData.dataModel, dataModelObj?.DataModelID, '');
      }

      setPathParameterArray(updatedPathParameterArray);
      setQueryParameterArray(updatedQueryParameterArray);
      setHeaderParameterArray(updatedHeaderParameterArray);
      setRequestBodyArray(updatedRequestBodyArray);

      const parameter = new RequestParameter(
        id,
        formData.parameterName,
        formData.parameterType,
        formData.description,
        formData.dataType,
        formData.required === 'yes' ? true : false,
        formData.default,
        updatedRequestBody
      );

      updatedRequestParametersList = [...requestParametersList, parameter];
      setRequestParametersList(updatedRequestParametersList);
      sessionStorage.setItem('requestParametersList', JSON.stringify(updatedRequestParametersList));
      console.log(updatedRequestParametersList);

      dispatch(showSuccessMessage('Request Parameter saved successfully'));
      setFormData(initialFormData);
    }
  };

  const validateAPIRequestForm = () => {
    let valid = true;

    const newErrors = {
      parameterName: '',
      parameterType: '',
      description: '',
      dataType: '',
      dataModel: '',
      required: '',
      default: '',
    };

    if (formData.parameterName.trim() === '') {
      newErrors.parameterName = 'Parameter Name is required';
      valid = false;
    }
    if (formData.parameterType.trim() === '') {
      newErrors.parameterType = 'Parameter Type is required';
      valid = false;
    }
    if (formData.description.trim() === '') {
      newErrors.description = 'Description is required';
      valid = false;
    }
    if (formData.dataType.trim() === '') {
      newErrors.dataType = 'Data Type is required';
      valid = false;
    }
    if (formData.dataType === 'object' && formData.parameterType === 'requestBody') {
      if (formData.dataModel.trim() === '') {
        newErrors.dataModel = 'Data Model is required';
        valid = false;
      }
    }
    setApiRequestFormDataErrors(newErrors);

    return valid;
  };

  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    authorizedNavigation(getOnlineNavigation());
    setLoading(true);

    const sessionProjectId = sessionStorage.getItem('currentUserProjectID');
    ApiService.getDataModelbyProjectId(instance, sessionProjectId)
      .then((response) => {
        console.log('models data', response);
        setDataModels(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Loading loading={loading} />
      {/* Header */}
      <div className="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div className="flex-1 min-w-0">
          {/* Title */}
          <div className="mt-2">
            <h4 className="text-1xl md:text-2xl font-bold tracking-tight leading-7 sm:leading-10 truncate">
              Request Parameters
            </h4>
          </div>
        </div>
      </div>

      {/* Main */}
      <div className="flex-auto p-6 sm:p-10">
        <div className="max-w-xl">
          <form className="flex flex-col">
            {/* Text Field */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="textField" required>
                  Parameter Name
                </InputLabel>
                <Input
                  id="textField"
                  type="text"
                  inputProps={{ maxLength: 30 }}
                  required
                  value={formData.parameterName}
                  onChange={handleParameterNameChange}
                />
              </FormControl>
              <span style={{ color: 'red' }}>{apiRequestFormDataErrors.parameterName}</span>
            </div>
            {/* Dropdown */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="dropdown" required>
                  Parameter Type
                </InputLabel>
                <Select
                  id="dropdown"
                  label="Parameter Type"
                  required
                  value={formData.parameterType}
                  onChange={(event) => {
                    onChangeParameterType(event);
                    handleParameterTypeChange(event);
                  }}
                >
                  <MenuItem value="Cookie Items">Cookie items</MenuItem>
                  <MenuItem value="HTTP Header">HTTP Header</MenuItem>
                  <MenuItem value="requestBody">Request Body</MenuItem>
                  <MenuItem value="URI Path Segment">URI Path Segment</MenuItem>
                  <MenuItem value="Query Parameters">Query Parameterss</MenuItem>
                </Select>
              </FormControl>
              <span style={{ color: 'red' }}>{apiRequestFormDataErrors.parameterType}</span>
            </div>
            {/* Text Field */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="textField" required>
                  Description
                </InputLabel>
                <Input
                  id="textField"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  required
                  value={formData.description}
                  onChange={handleDescriptionChange}
                />
              </FormControl>
              <span style={{ color: 'red' }}>{apiRequestFormDataErrors.description}</span>
            </div>
            {/* Dropdown */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="dropdown" required>
                  Data Type
                </InputLabel>
                <Select
                  id="dropdown"
                  label="Data Type"
                  required
                  value={formData.dataType}
                  onChange={(event) => {
                    onChangeDataType(event);
                    handleDataTypeChange(event);
                  }}
                >
                  <MenuItem value="integer">Integer</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="boolean">Boolean</MenuItem>
                  <MenuItem value="object">Object</MenuItem>
                  <MenuItem value="file">File</MenuItem>
                  <MenuItem value="int32">int32</MenuItem>
                  <MenuItem value="int64">int64</MenuItem>
                </Select>
              </FormControl>
              <span style={{ color: 'red' }}>{apiRequestFormDataErrors.dataType}</span>
            </div>
            {/* Dropdown */}
            {displayDataModel && (
              <div className="flex flex-col mb-6">
                <FormControl className="mb-2">
                  <InputLabel htmlFor="dropdown" required>
                    Data Model
                  </InputLabel>
                  <Select
                    id="dropdown"
                    label="Data Model"
                    required
                    value={formData.dataModel}
                    onChange={handleDataModelChange}
                  >
                    {dataModels.map((dataModel: any, index: number) => {
                      return (
                        <MenuItem value={dataModel.DataModelName} key={index}>
                          {dataModel.DataModelName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <span style={{ color: 'red' }}>{apiRequestFormDataErrors.dataModel}</span>
              </div>
            )}

            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="dropdown">Required</InputLabel>
                <Select
                  id="dropdown"
                  label="Required"
                  value={formData.required}
                  onChange={handleRequiredChange}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* Text Field */}
            <div className="flex flex-col mb-6">
              <FormControl className="mb-2">
                <InputLabel htmlFor="textField">Default</InputLabel>
                <Input
                  id="textField"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  value={formData.default}
                  onChange={handleDefaultChange}
                />
              </FormControl>
            </div>

            {/* Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={saveRequestParameterData}
              type="button"
              className="mt-4 w-[120px]"
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApiRequestParameters;
