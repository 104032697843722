import { PropsWithChildren, ChangeEventHandler } from 'react';
import { Button } from '@mui/material';

type IProps = PropsWithChildren & {
  onClick: () => void;
  size: 'small' | 'medium' | 'large';
  id: string;
  name: string;
  accept: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  required: boolean;
  multiple: boolean;
};

export default function FileUploadButton({
  id,
  name,
  accept,
  onChange,
  onClick,
  children,
  size,
  required,
  multiple,
}: IProps) {
  return (
    <div>
      <input
        className="p-10 text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold"
        type="file"
        style={{ display: 'none' }}
        id={id}
        name={name}
        accept={accept}
        required={required}
        onChange={onChange}
        multiple={multiple}
      />

      <Button type="button" variant="contained" size={size} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
}
