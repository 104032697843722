import styled from '@emotion/styled';
import { Box } from '@mui/system';
import BGImage from 'assets/LandingPageBG.svg';
import logo from 'assets/logo.svg';

const SplashScreenWrapper = styled(Box)`
  height: 100vh;
  display: grid;
  background-image: url(${BGImage});
  background-repeat: no-repeat;
  backface-visibility: visible;
  background-size: cover;

  body {
    height: 100%;
    background: #f6f7fd;
    font-size: 16px;
  }
  .snippet {
    position: relative;
    padding: 10px 15%;
    margin: 0% 10%;
    border-radius: 16px;
  }

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 32px 0;
    margin: 0 2%;
    overflow: hidden;
  }
  .dot-pulse {
    position: relative;
    left: -9980px;
    width: 20px;
    height: 20px;
    bottom: 40%;
    border-radius: 10px;
    background-color: #9880ff;
    color: #0d4c92;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }
  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #9880ff;
    color: #0d4c92;
  }
  .dot-pulse::before {
    box-shadow: 9984px 0 0 0px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  .srcLogo {
    image: url(${logo});
    repeat: no-repeat;
    position: relative;
    padding-top: 25%;
    padding-left: 5%;
  }

  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9980px 0 0 -5px;
    }
    30% {
      box-shadow: 9980px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9980px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 10000px 0 0 -5px;
    }
    30% {
      box-shadow: 10000px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10000px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10020px 0 0 -5px;
    }
    30% {
      box-shadow: 10020px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10020px 0 0 -5px;
    }
  }
`;

export default function SplashScreen() {
  return (
    <Box>
      <SplashScreenWrapper>
        <div className="snippet">
          <div className="srcLogo flex flex-col justify-center items-center">
            <img src={logo} alt="hexaware" />
          </div>
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
      </SplashScreenWrapper>
    </Box>
  );
}
