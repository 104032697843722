import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialIPDialogState = {
  open?: boolean;
  title: string;
  animationKey: string;
};

const initialState: InitialIPDialogState = {
  open: false,
  title: '',
  animationKey: '',
};

const IPDialogSlice = createSlice({
  name: 'processDialog',
  initialState,
  reducers: {
    openProcessDialog: (state, action: PayloadAction<InitialIPDialogState>) => {
      state.open = true;
      state.title = action.payload.title;
      state.animationKey = action.payload.animationKey;
    },
    closeProcessDialog: (state) => {
      state.open = false;
    },
  },
});

export const { openProcessDialog, closeProcessDialog } = IPDialogSlice.actions;
export default IPDialogSlice.reducer;
