import { apiKey } from './apiKey.model';
import { HTTPBasicAuth } from './httpbasic.model';
import { HTTPBearerAuth } from './httpBearer.model';
import { oAuth } from './oAuth.model';
import { openIDConnect } from './openIDConnect.model';

export class APISecurity {
  basicAuthenticationList: HTTPBasicAuth[];
  bearerAuthenticationList: HTTPBearerAuth[];
  apiKeyAuthenticationList: apiKey[];
  oAuthList: oAuth[];
  openIDConnectAuthenticationList: openIDConnect[];
  constructor(
    basicAuthenticationList: HTTPBasicAuth[],
    bearerAuthenticationList: HTTPBearerAuth[],
    apiKeyAuthenticationList: apiKey[],
    oAuthList: oAuth[],
    openIDConnectAuthenticationList: openIDConnect[]
  ) {
    this.basicAuthenticationList = basicAuthenticationList;
    this.bearerAuthenticationList = bearerAuthenticationList;
    this.apiKeyAuthenticationList = apiKeyAuthenticationList;
    this.oAuthList = oAuthList;
    this.openIDConnectAuthenticationList = openIDConnectAuthenticationList;
  }
}
