import { Alert as MuiAlert, AlertProps, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'app/store';
import { hideMessage } from 'app/layouts/store/slices/message.slice';
import { forwardRef } from 'react';
import Slide from '@mui/material/Slide';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Message() {
  const { open, options } = useSelector((state) => state.layoutSlice.message);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(hideMessage());
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={options.autoHideDuration}
      anchorOrigin={options.anchorOrigin}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <Alert onClose={handleClose} severity={options.variant} sx={{ width: '100%' }}>
        {options.message}
      </Alert>
    </Snackbar>
  );
}

export default Message;
