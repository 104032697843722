import { useState } from 'react';

interface DialogContent {
  message: string;
  link?: any;
}

interface DialogHook {
  dialogContent: DialogContent;
  isDialogOpen: boolean;
  showDialog: (message: string, link?: any) => void;
  handleCloseDialog: () => void;
}

const useDialog = (): DialogHook => {
  const [dialogContent, setDialogContent] = useState<DialogContent>({ message: '', link: '' });
  const [isDialogOpen, setDialogOpen] = useState(false);

  const showDialog = (message: string, link?: string) => {
    setDialogContent({ message, link: link || '' });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return { dialogContent, isDialogOpen, showDialog, handleCloseDialog };
};

export default useDialog;
