import { IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import ConfigLoaderService from '../app/services/config-loader.service';
/*eslint import/no-cycle: [2, { maxDepth: 2 }]*/
import { Services } from 'app/models';
import store from '../app/store';

export class RequestHandler {
  scope: string[] = [];
  url: string = '';
  axiosInstance: AxiosInstance = axios.create();

  constructor(private readonly service: Services) {
    ConfigLoaderService.loadConfig().then((response) => {
      const apiConfig = response.API_CONFIGS.filter((item) => item.SERVICE === this.service);

      this.scope = [apiConfig[0].SCOPE];
      this.url = apiConfig[0].URL;
      const options = {
        baseURL: apiConfig[0].URL,
        headers: {
          'Content-type': 'application/json',
          'X-Content-Type-Options': 'nosniff',
        },
      };
      this.axiosInstance = axios.create(options);
    });
  }

  requestHandler(instance: IPublicClientApplication) {
    this.axiosInstance.interceptors.request.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (req: any) => {
        const accessTokenRequest = {
          scopes: this.scope,
          account: instance.getAllAccounts()[0],
        };
        try {
          const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
          req.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;

          const storeState = store.getState();
          const stTenantId = storeState.authSlice.user.tenant || storeState.authSlice.tenant._id;
          req.headers.tenantid = stTenantId;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error.errorMessage.indexOf('not consented to use the application') !== -1) {
            instance.acquireTokenRedirect(accessTokenRequest).then(() => {
              // instance.handleRedirectPromise(() => {
              //     console.log("error while handling redirect", error, response);
              // });
            });
          }
        }
        return req;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (err: any) => {
        return Promise.reject(err);
      }
    );
    return this.axiosInstance;
  }
}
