import { createAsyncThunk } from '@reduxjs/toolkit';
import azureDevopsService from '../../../../services/app-designer/azure-devops.service';
import { IPublicClientApplication } from '@azure/msal-browser';

export const fetchOrgs = createAsyncThunk(
  'azureDevopsSlice/fetchOrgs',
  async (data: WithInstance<{ code: string; instance: IPublicClientApplication }>) => {
    try {
      const response = await azureDevopsService.getOrganizations(data.instance, data.code);
      console.log('org list', response.data);
      localStorage.setItem('azDevopsBearer', response.data.bearerToken);
      return response.data.value;
    } catch {
      return [];
    }
  }
);

export const generatePatToken = createAsyncThunk(
  'azureDevopsSlice/generatePatToken',
  async (data: WithInstance<{ instance: IPublicClientApplication; orgName: string }>) => {
    try {
      const code = localStorage.getItem('azDevopsBearer')!;
      const response = await azureDevopsService.getAccessToken(data.instance, code, data.orgName);
      console.log('pat info', response.data);
      localStorage.removeItem('azDevopsBearer');
      return response.data;
    } catch {
      return [];
    }
  }
);

export const fetchProjects = createAsyncThunk(
  'azureDevopsSlice/fetchProjects',
  async (
    data: WithInstance<{ token: string; instance: IPublicClientApplication; orgName: string }>
  ) => {
    try {
      const response = await azureDevopsService.getProjects(
        data.instance,
        data.token,
        data.orgName
      );
      console.log('project list', response.data);
      return response.data;
    } catch {
      return [];
    }
  }
);

export const fetchRepos = createAsyncThunk(
  'azureDevopsSlice/fetchRepos',
  async (
    data: WithInstance<{
      token: string;
      instance: IPublicClientApplication;
      orgName: string;
      projectName: string;
    }>
  ) => {
    try {
      const response = await azureDevopsService.getRepos(
        data.instance,
        data.token,
        data.orgName,
        data.projectName
      );
      console.log('repo list', response.data);
      return response.data;
    } catch {
      return [];
    }
  }
);

export const fetchBranches = createAsyncThunk(
  'azureDevopsSlice/fetchBranches',
  async (
    data: WithInstance<{
      token: string;
      instance: IPublicClientApplication;
      orgName: string;
      projectName: string;
      repoId: string;
    }>
  ) => {
    try {
      const response = await azureDevopsService.getBranches(
        data.instance,
        data.token,
        data.orgName,
        data.repoId,
        data.projectName
      );
      console.log('branch list', response.data);
      return response.data;
    } catch {
      return [];
    }
  }
);
