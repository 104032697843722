import { createAsyncThunk } from '@reduxjs/toolkit';
import nonMandatoryDetailsService from 'app/services/management/non-mandatory-details.service';
import projectService from 'app/services/management/project.service';
import { ProjUsers } from '../../types';

export const fetchProjectsList = createAsyncThunk(
  'dashboard/fetchProjectsList',
  async (data: ActionPayload) => {
    const response = await projectService.getAll(data.instance);
    return response.data;
  }
);

export const fetchNonManditoryDetails = createAsyncThunk(
  'dashboard/fetchNonManditoryDetails',
  async (data: ActionPayload) => {
    const response = await nonMandatoryDetailsService.getAllNonMandatoryProjDetails(data.instance);
    return response.data;
  }
);

export const fetchProjUsersList = createAsyncThunk(
  'dashboard/fetchProjUsersList',
  async (data: WithInstance<{ projectId: string }>) => {
    const response = await projectService.findUsers(data.projectId, data.instance);
    // console.log(response, 'response');
    const projUsers: ProjUsers = {};
    for (const projUser of response.data) {
      projUsers[projUser._id] = projUser;
    }
    return projUsers;
  }
);
export const fetchProjectById = createAsyncThunk(
  'dashboard/fetchProjectById',
  async (data: ActionPayload<{ projectId: string }>) => {
    const response = await projectService.findOne(data.projectId, data.instance);
    return response.data;
  }
);
