import { IPublicClientApplication } from '@azure/msal-browser';
import {
  AddApplicationUserDTO,
  CreateNewRepositoryDTO,
  IsUserAuthorizedDTO,
  LinkRepositoryDTO,
  LoadRepositoryBranchesDTO,
} from 'app/dtos';
import { Services } from 'app/models';
import { AxiosRequestConfig } from 'axios';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'app-designer';

class ApplicationUserService extends RequestHandler {
  endpath = 'applicationusers';

  generateAccessToken(instance: IPublicClientApplication, payload: any) {
    return this.requestHandler(instance).post(`${this.endpath}/getGithubAccessToken`, payload);
  }

  addApplicationUser(instance: IPublicClientApplication, payload: AddApplicationUserDTO) {
    const headers = { applicationid: payload.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(`${this.endpath}/owner/add`, payload, config);
  }

  loadRepositories(instance: IPublicClientApplication, token: string) {
    const data = {
      token,
    };
    return this.requestHandler(instance).post(`/${this.endpath}/loadRepositories/`, data);
  }

  createNewRepository(instance: IPublicClientApplication, payload: CreateNewRepositoryDTO) {
    const headers = { applicationid: payload.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `/${this.endpath}/create/new-repository/`,
      payload,
      config
    );
  }

  loadRepositoryBranches(instance: IPublicClientApplication, payload: LoadRepositoryBranchesDTO) {
    return this.requestHandler(instance).post(`/${this.endpath}/loadRepositoryBranches`, payload);
  }

  linkExistingRepository(instance: IPublicClientApplication, payload: LinkRepositoryDTO) {
    const headers = { applicationid: payload.appid };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `/${this.endpath}/link/user/existingrepository/`,
      payload,
      config
    );
  }

  checkIfUserIsAuthorized(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IsUserAuthorizedDTO>(
      `/${this.endpath}/check/isAuthorized/${appId}`,
      config
    );
  }

  async getCodeSpaceUrl(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(`/${this.endpath}/codeSpaceUrl/${appId}`, config);
  }
}

export default new ApplicationUserService(apiServiceName);
