import { IPublicClientApplication } from '@azure/msal-browser';
import { IUser, AddEditUserInterface } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName = 'iam';

class UserService extends RequestHandler {
  endpath = 'user';

  findUserByRole(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get<IUser[]>(`/${this.endpath}/tenant/${tenantId}`);
  }
  fetchLocationsByTenant(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get(`/location/tenant/${tenantId}`);
  }
  addUser(instance: IPublicClientApplication, addUserData: AddEditUserInterface) {
    return this.requestHandler(instance).post<AddEditUserInterface>(
      `/${this.endpath}`,
      addUserData
    );
  }
  update(instance: IPublicClientApplication, id: string, editUserData: AddEditUserInterface) {
    return this.requestHandler(instance).patch<AddEditUserInterface>(
      `/${this.endpath}/${id}`,
      editUserData
    );
  }
  deleteUserRequest(requestId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).delete(`/${this.endpath}/${requestId}`);
  }

  validateUser(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<IUser>(`${this.endpath}/validate`);
  }

  updateLoginCountAndTime(instance: IPublicClientApplication, email: string) {
    return this.requestHandler(instance).patch(`${this.endpath}/updateLoginCountAndTime/${email}`);
  }

  userActivationToggle(instance: IPublicClientApplication, id: string, isactive: boolean) {
    return this.requestHandler(instance).patch(
      `/${this.endpath}/useractivation/${id}/toggle/${isactive}`
    );
  }
  getUserByEmail(email: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/emailId/${email}`);
  }
}

export default new UserService(apiServiceName);
